.titleSection {
    width: 100%;
    background: var(--color-premium-blue);
    border-top: .5px solid #fff;
}

.titleContainer {
    display: flex;
    padding: 30px 0;
    gap: 10px;
}

.titleContainer img {
    width: 30px;
}

.titleContainer h2 {
    color: #fff;
    font-weight: 500;
    font-size: 2rem;
}

.titleContainer h1:hover {
    color: #fff;
}

.container {
    position: relative;
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .titleContainer img {
        display: none;
    }
    .titleContainer h2 {
        color: #fff;
        font-weight: 500;
        font-size: 1.5rem;
    }
}