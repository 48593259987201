.index-container {
  width: 100%;
  height: 100vh;
}

.amc-domestico-index-header {
  width: 100%;
  height: 22%;
}

.amc-domestico-index-header .amc-domestico-index-header-img {
  background-color: #020c41;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75%;
}

.amc-domestico-index-header-img .logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
}

.amc-domestico-index-header-img .logo img {
  width: 300px;
  height: 300px;
}

.amc-domestico-index-header-img .lines_pink {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  width: 25%;
}

.amc-domestico-index-header-img .lines_pink img {
  width: 45%;
  height: 100%;
  margin-right: -65px;
}

.amc-domestico-index-header .amc-domestico-index-header-hours {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: 15px;
}

.amc-domestico-index-header-hours .hours {
  background-color: #030d75;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.amc-domestico-index-content {
  width: 100%;
  height: 78%;
}

.content-index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.content-index .content-index-title {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.content-index .content-index-type-profile {
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content-index-type-profile .circles-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 70%;
}

.content-index-type-profile .index-profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.3%;
  height: 100%;
}

.index-profile-container .content-index-circle {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #123660;
  width: 50%;
  height: 60%;
}

.index-profile-container .content-index-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content-index .content-index-buton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20%;
}

.content-index-buton .content-index-buton-next {
  padding: 10px;
  width: 22%;
  font-size: 15px;
  font-weight: bold;
  background-color: #035cf7;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.alert-text {
  color: red;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  text-align: center;
}

/* ============================================================
  TABLETS
  ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .amc-domestico-index-header-img .logo {
    justify-content: center;
    width: 68%;
  }

  .amc-domestico-index-content {
    width: 100%;
    height: 78%;
  }

  .content-index .content-index-title {
    font-size: 30px;
  }

  .content-index-type-profile .circles-container {
    width: 90%;
    height: 90%;
  }

  .content-index-type-profile .index-profile-container {
    width: 33.3%;
    height: 60%;
  }

  .index-profile-container .content-index-circle {
    width: 70%;
    height: 60%;
  }

  .content-index-buton .content-index-buton-next {
    width: 32%;
  }
}

/* ============================================================
  PHONE
  ============================================================ */
@media screen and (max-width: 600px) {
  .amc-domestico-index-header {
    height: 18%;
  }

  .amc-domestico-index-header .amc-domestico-index-header-img {
    height: 70%;
  }

  .amc-domestico-index-header-img .logo {
    width: 100%;
  }

  .amc-domestico-index-header-img .lines_pink {
    display: none;
  }

  .amc-domestico-index-header .amc-domestico-index-header-hours {
    width: 100%;
    height: 30%;
    flex-direction: column;
  }

  .amc-domestico-index-header-hours .hours {
    width: 100%;
    height: 50%;
  }

  .amc-domestico-index-content {
    width: 100%;
    height: 82%;
  }

  .content-index .content-index-title {
    font-size: 20px;
  }

  .content-index-type-profile .circles-container {
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    height: 100%;
  }

  .content-index-type-profile .index-profile-container {
    width: 40%;
    height: 60%;
  }

  .index-profile-container .content-index-circle {
    width: 100%;
    height: 60%;
  }

  .content-index-buton .content-index-buton-next {
    width: 52%;
  }
}
