.static-nav-mb {
    width: 100%;
    padding-bottom: 0px;
}
.navbar-aero {
    min-width: 100%;
    background-color: var(--color-blue-hard);
    height: 61px;
    display: flex;
    align-items: center;
    padding-right: 5% !important;
    padding-left: 5% !important;
}
.logo-amc {
    width: 120px;
    height: auto;
}
.menu-icon {
    display: none;
}
.nav-menu {
    display: grid;
    grid-template-columns: repeat(7 , auto);
    grid-gap: 7px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: end;
    margin: 0;
    padding: 0;
}
.nav-item {
    display: flex;
    align-items: center;
}
.nav-item:hover {
    border-radius: 20px;
    background-color: hsla(0,0%,100%,0.15);
}
.web-item-none {
    display: none;
}
.mobile-item-none {
    display: block;
} 
.btn-language {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    background-color: transparent;
    border-radius: 20px;
    gap: 5px;
    padding: 5px 8px;
    border: none;
}
.btn-language-active {
    background-color: hsla(0,0%,100%,0.15);
}
.btn-language p {
    margin: 0;
    padding: 0;
    font-weight: bold;
}
.btn-language img {
    width: 13px;
}
.btn-divider{
    color: white;
}
.border-btn {
    border-bottom: none;
}

@media screen and (max-width: 1024px) {
    .static-nav-mb {
        width: 100%;
        padding-bottom: 61px;
    }
    .navbar-aero {
        width: 100%;
        background-color: var(--color-blue-hard);
        height: 61px;
        display: flex;
        align-items: center;
        padding-right: 5% !important;
        padding-left: 5% !important;
        position: fixed;
        overflow: hidden;
        z-index: 1000;
    }
    .menu-icon {
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        top: 6px;
        right: 5%;
        font-size: 1.8rem;
        cursor: pointer;
    }
    .nav-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        position: fixed;
        overflow-x: hidden;
        overflow-y: auto;
        top: 61px;
        right: -100%;
        opacity: 1;
        transform: translateX(100%);
        transition: all 1s ease;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        padding-bottom: 15px;
    }
    .nav-menu.active {
        display: flex;
        height: 100%;
        background: #ffffff;
        right: 0;
        opacity: 1;
        transform: translateX(0);
        z-index: 1000;
    }
    .nav-item {
        display: grid;
        align-items: center;
    }
    .web-item-none {
        display: block;
    }
    .mobile-item-none {
        display: none;
    }
    .mobile-menu-item {
        display: block;
        color: #999;
        font-size: 10px;
        text-align: start;
        margin: 0;
        padding: 0 10px;
        width: 100%;
    }
    .nav-links {
        color: #0B2343;
        font-size: 18px;
        font-weight: bold;
        text-align: start;
        padding: 6px 10px;
        width: 100%;
        display: table;
        text-decoration: none;
    }
    .border-btn {
        border-bottom: 1px solid #e5e5e5;
    }
    .bg-gray {
        background-color: #f7f7f7;;
    }
    .btn-language {
        font-size: 18px;
        color: var(--color-blue-hard);
    }
    .btn-divider{
        color: var(--color-blue-hard);
    }
  }