.errorContainer {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.errorContainer .errorTitlte {
  font-weight: bold;
  font-size: 2rem;
  color: var(--color-premium-blue);
}

.errorContainer .errorDescription {
  color: #767676;
  margin-bottom: 30px;
}

.errorContainer button {
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  background: var(--color-azul-innovacion);
  color: #fff;
  font-weight: bold;
}
