thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.national-stations.up {
  width: 100%;
  height: 45px;
  max-height: 45px;
  overflow: hidden;
  transition: .3s ease all;
}

.national-stations.down {
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow: hidden;
  transition: .3s ease all;
}

.national-stations .headerAccordion {
  background: var(--color-premium-blue);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
}

.national-stations .headerAccordion .actionBtns {
  display: flex;
  gap: 15px;
  margin-right: 10px;
}

.headerAccordion .actionBtns img {
  width: 20px;
}

.headerAccordion .actionBtns .icon-arrow.down {
  color: #fff;
  transition: .3s ease all;
  transform: rotate(0deg);
  font-size: 1.3rem;
}

.headerAccordion .actionBtns .icon-arrow.up {
  color: #fff;
  transition: .3s ease all;
  transform: rotate(180deg);
}

.national-stations p {
  margin: 0;
  padding: 0;
  color: #fff;
}

.table-responsive {
  max-height: 400px;
  overflow-x: scroll;
  overflow-y: scroll;
}

/* Scrollbar vertical */
.table-responsive::-webkit-scrollbar {
  width: 1rem !important;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #020c41;
  border: 0.8rem solid #020c41 !important;
}

/* Scrollbar horizontal */
.table-responsive::-webkit-scrollbar-track {
  background-color: #ffffff; /* Cambia el color del track (fondo) del scrollbar horizontal si lo deseas */
}

.table-responsive::-webkit-scrollbar-thumb:horizontal {
  border: 0.8rem solid #020c41 !important;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.panel-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .colum-left{
    margin-bottom: 10px;
  }
  
  .searchInput{
    margin-right: 20px;
  }
  
  .colum-rigth {
    margin-bottom: 10px;
    margin-right: 40px;
  }
  
  .btnn {
	box-shadow:inset 0px 0px 11px 1px #23395e;
	background:linear-gradient(to bottom, #020c41 5%, #020c41 100%);
	background-color:#2e466e;
	border-radius:37px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	padding:8px 20px;
	text-decoration:none;
}
.btnn:hover {
	background:linear-gradient(to bottom, #415989 5%, #2e466e 100%);
	background-color:#415989;
}
.btnn:active {
	position:relative;
	top:1px;
}

.editButton {
    font-size: 23px;
    font-weight: 600;
    margin-right: 10px;
    color: #0e2240;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}

.editButton:hover {
    transform: scale(1.2);
}

.detailsButton{
    font-size: 23px;
    font-weight: 600;
    margin-right: 10px;
    color: #6b86bf;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}

.detailsButton:hover {
    transform: scale(1.2);
}
.infoButton{
  font-size: 23px;
  font-weight: 600;
  margin-right: 10px;
  color: var(--color-azul-innovacion);
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
}

.infoButton:hover {
  transform: scale(1.2);
}

.deleteButton{
    font-size: 23px;
    font-weight: 600;
    color: #ff0000;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}
.deleteButton:hover {
    transform: scale(1.2);
}

.estationsPpopup .select-contain{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  margin-left: -20px;
}

.estationsPpopup label {
  margin-right: 10px;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  
}


@media screen and (max-width: 600px) {
  .table-responsive::-webkit-scrollbar {
    width: 1rem !important;
  }
  
  .table-responsive::-webkit-scrollbar-thumb {
    background-color: #020c41;
    border: 10px solid #020c41 !important;
  }
  
  /* Scrollbar horizontal */
  .table-responsive::-webkit-scrollbar-track {
    background-color: #ffffff; /* Cambia el color del track (fondo) del scrollbar horizontal si lo deseas */
  }
  
  .table-responsive::-webkit-scrollbar-thumb:horizontal {
    border: 10px solid #020c41 !important;
  }
  
  .panel-table {
    display: flex;
    flex-direction: column;
  }
  
  .colum-left{
    margin-bottom: 10px;
  }
  
  .searchInput{
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .colum-rigth {
    margin-bottom: 20px;
    margin-left: -90px;
  }
  
  .btnn {
    box-shadow:inset 0px 0px 11px 1px #23395e;
    background:linear-gradient(to bottom, #020c41 5%, #020c41 100%);
    background-color:#2e466e;
    border-radius:37px;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:13px;
    padding:8px 20px;
    text-decoration:none;
  }
  .btnn:hover {
    background:linear-gradient(to bottom, #415989 5%, #2e466e 100%);
    background-color:#415989;
  }
  .btnn:active {
    position:relative;
    top:1px;
  }
}


/* =====================================================
MODAL
===================================================== */
.station-modal {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-modal-station {
  width: 100%;
  height: 100%;
  background: #04040491;
  position: absolute;
  z-index: 1;
}

.station-modal section {
  z-index: 5;
  width: 100%;
  max-width: 550px;
  height: auto;
  max-height: 90vh;
  overflow: auto;
  background: #fff;
}

section .header-station {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #eaeaea;
}

section .header-station h4 {
  margin: 0;
  padding: 0;
  font-weight: bolder;
  color: #727272;
  font-size: 1.2rem;
}

section .body-station {
  width: 100%;
  height: auto;
  /* padding: 10px 20px; */
  padding-top: 10px;
  padding-bottom: 0px;
}

section .body-station form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.input-container-stations label {
  width: auto;
  min-width: 100px;
}

.input-container-stations input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #626262;
  padding: 3px 10px;
}

.input-container-stations {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 20px;
}

.editorStations {
  background: #ffffff;
  border: 1px solid #626262;
  border-radius: 5px;
  margin-bottom: 10px;
}

.selects-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.selects-container .select-input {
  display: flex;
  gap: 15px;
}

.slc-lng {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.body-station select {
  width: 90px;
}

.btn-container-stations {
  width: 100%;
  background: var(--color-premium-blue);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.btn-container-stations button {
  border: none;
  padding: 3px 10px;
  border-radius: 4px;
}

.btn-container-stations .validClass {
  background: var(--color-blue-light);
  color: #fff;
  transition: .3s ease all;
}

.btn-container-stations .invalidClass {
  background: #888888;
  color: #fff;
  transition: .3s ease all;
}

.btn-container-stations .closeModal {
  background: var(--color-red);
  color: #fff;
  transition: .3s ease all;
}

.btn-container-stations .closeModal:hover {
  background: #c2192a;
  color: #fff;
  transition: .3s ease all;
}

.searchStationInputContainer {
  width: 100%;
  padding: 5px 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.stationsRestoreContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.stationsRestoreContainer .stationItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
}

.stationsRestoreContainer p {
  margin: 0;
  padding: 0;
}

.stationsRestoreContainer .lngStationRestore {
  display: flex;
  gap: 5px;
}

.stationsRestoreContainer .lngStationRestore img {
  width: 20px;
}

.stationsRestoreContainer td {
  border-bottom: none;
}

.stationsRestoreContainer tr {
  border-bottom: 1px solid #eee;
}

.buttonsRestore {
  display: flex;
  gap: 10px;
}