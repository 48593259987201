.setter-form {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.setter-form h6 {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.heavy-form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0 0px 0;
  flex: 1;
  position: relative;
}

.heavy-form-group p {
  font-size: .8rem;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  margin-bottom: -10px;
  color: #dc0f38;
}

.heavy-form-group input {
  width: 100%;
  background: none;
  font-size: 1rem;
  padding: 20px 10px 5px 10px;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 2px solid #ccc;
  font-weight: bold;
}

.heavy-form-group select {
  width: 100%;
  background: none;
  font-size: 1rem;
  padding: 15px 10px 10px 10px;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 2px solid #ccc;
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../../../../../assets/imgs/shared/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  box-sizing: border-box;
  background-size: 20px;
  cursor: pointer;
}

.heavy-form-group input:focus + label,
.heavy-form-group input:not(:placeholder-shown) + label {
  transform: translateY(-1px) scale(0.7);
  transform-origin: left top;
  color: var(--color-azul-consistencia);
}

.heavy-form-group label {
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 12px;
  transform: translateY(10px);
  transition: transform 0.5s #000 0.3s;
  transition: 0.3s ease all;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: #000;
}

.two-inputs-contain {
  width: 100%;
  height: auto;
  min-height: 20px;
  display: flex;
  gap: 10px;
}

.setter-form .btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.setter-form .btn-container button {
  display: flex;
  border: none;
  padding: 10px;
  border-radius: 3px;
  background: var(--color-azul-innovacion);
  color: #fff;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.consignee-content {
  position: relative;
}

.consignee-content .answer-consignee {
  position: absolute;
  width: 100%;
  height: 0px;
  max-height: 0;
  background: #ffffff;
  top: 50px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 10;
  border: none;
  transition: max-height .3s ease;
}

.consignee-content .answer-consignee.active {
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 120px;
  background: #e8e8e8;
  top: 52px;
  border-radius: 5px;
  overflow: auto;
  z-index: 10;
  border: 2px solid #e8e8e8;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.consignee-content .answer-consignee p {
  padding: 10px 15px;
  cursor: pointer;
  color: #000;
  margin: 0;
  border-bottom: 1px solid #ccc;
  transition: .3s ease all;
}

.consignee-content .answer-consignee p:hover {
  background: var(--color-azul-innovacion);
  color: #fff;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 712px) and (max-width: 912px) {
  .setter-form {
    width: 60%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 711px) {
  .setter-form {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
}