.home-form {
  margin: 0;
  padding: 0;
  display: contents;
}

#label-content .text-bold {
  font-weight: bolder;
}

.amc-home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: #ffffff;
}

.amc-home-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
}

.amc-home-content .amc-domestico-home-content-content {
  width: 85%;
}

.amc-home-content
  .amc-domestico-home-content-content
  .amc-domestico-home-content-content-title {
  height: 20%;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}

.amc-home-content
  .amc-domestico-home-content-content
  .amc-domestico-home-content-content-form {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.amc-home-content
  .amc-domestico-home-content-content
  .amc-domestico-home-content-content-form
  .amc-domestico-home-content-content-form-title {
  margin-top: -128px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.amc-home-content
  .amc-domestico-home-content-content
  .amc-domestico-home-content-content-form
  .amc-domestico-home-content-content-form-title
  p {
  color: #000;
  font-weight: bold;
}

.amc-home-content
  .amc-domestico-home-content-content
  .amc-domestico-home-content-content-form
  .amc-domestico-home-content-content-form-inputs {
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.amc-domestico-home-content-content-form-inputs-name {
  width: 100%;
}

.amc-domestico-home-content-content-form-inputs-row {
  display: flex;
  gap: 5px;
  width: 100%;
}

.amc-domestico-home-content-content-form-inputs-pieces,
.amc-domestico-home-content-content-form-inputs-destination {
  width: 50%;
}

/* estilod de los input */
.amc-domestico-home-content-content-form-inputs .formGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0 10px 0;
  width: 100%;
}

.amc-domestico-home-content-content-form-inputs .formGroup input,
.amc-domestico-home-content-content-form-inputs .formGroup textarea,
.date-box {
  background: #ffffff;
  font-size: 0.9rem;
  border: none;
  outline: none;
  border-radius: 0px;
  border: 2px solid #d4d4d4;
  transition: 0.3s ease all;
}

.amc-domestico-home-content-content-form-inputs .formGroup input {
  height: 45px;
  min-height: 45px;
  padding: 10px 10px 1px 10px;
}

.amc-domestico-home-content-content-form-inputs
  .formGroup
  input:disabled
  + label {
  opacity: 0.3;
  cursor: auto;
}

.amc-domestico-home-content-content-form-inputs .formGroup input:focus {
  border: 2px solid #d4d4d4;
}

.amc-domestico-home-content-content-form-inputs .formGroup input:focus + label,
.amc-domestico-home-content-content-form-inputs
  .formGroup
  input:not(:placeholder-shown)
  + label {
  transform: translateY(1px) scale(0.6);
  transform-origin: left top;
  color: #323a8f;
}

.amc-domestico-home-content-content-form-inputs .formGroup input:focus {
  padding: 10px 10px 1px 10px;
}

.amc-domestico-home-content-content-form-inputs .formGroup label {
  cursor: pointer;
  position: absolute;
  top: 2px;
  transform: translateY(10px);
  transition: transform 0.5s #000 0.3s;
  transition: 0.3s ease all;
  font-size: 1rem;
  margin-bottom: 4px;
  color: #000;
  font-weight: lighter;
}

.amc-domestico-home-content-content-form-inputs .formGroup select {
  background: #ffffff;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  padding-right: 30px;
  border: none;
  outline: none;
  border-radius: 0px;
  border: 2px solid #d4d4d4;
  cursor: pointer;
  appearance: none;
  height: 45px;
}

.amc-domestico-home-content-content-form-inputs .formGroup select:disabled {
  cursor: auto;
  opacity: 0.3;
}

.selectGroup:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000000;
  border-radius: 10px;
  pointer-events: none;
}

.selectGroup.disabled:after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  pointer-events: none;
}

/* estilod de los input */

.amc-home-content .amc-domestico-home-content-buttons {
  background-color: #e5e5e5;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  padding: 30px;
}

.amc-home-content .amc-domestico-home-content-buttons .container-btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.next-shipment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.amc-button-primary {
  padding: 10px;
  font-size: 11px;
  font-weight: bold;
  background-color: #035cf7;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* ==============================================
  INPUTS CHECKBOX CLASS 
============================================== */
.input-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

input.square-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 2px solid #d4d4d4;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

label.check-live-animals {
  font-weight: lighter;
}

input.square-checkbox:checked {
  background-color: #035cf7;
  border-color: #d4d4d4;
}

input.square-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input.square-checkbox:hover {
  border-color: #035cf7;
}

/* Modal */
.custom-modal .modal-dialog,
.custom-modal-ticket .modal-dialog,
.custom-modal-confirm .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 668px;
  width: 100%;
}

.custom-modal-ticket .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 450px;
  width: 100%;
}

.custom-modal .modal-header {
  background-color: transparent;
  border: none;
  position: relative;
}

.custom-modal p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 900;
  color: #5f5353;
}

.custom-modal .custom-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #7ba9f7;
  border-top-color: #0b3f6a;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.custom-modal .modal-header button,
.custom-modal-ticket .modal-header button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff; /* Cambia el color según tu diseño */
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 10;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-modal .modal-header button:hover,
.custom-modal-ticket .modal-header button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.custom-modal-confirm .modal-icon {
  font-size: 50px;
  color: #001f5b;
  margin-bottom: 10px;
}

.custom-modal-confirm .modal-text {
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.custom-modal-confirm .modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.custom-modal-confirm .confirm-button {
  background-color: #035cf7;
  border: none;
  color: white;
  border-radius: 5px;
  width: 180px;
}

.custom-modal-confirm .cancel-button {
  background-color: #a3a3a3;
  border: none;
  color: white;
  border-radius: 5px;
  width: 180px;
}

.custom-modal-confirm .cancel-button:hover {
  background-color: #7a7a7a;
  color: white;
  border-color: none;
}

/* Ticket */
.amc-home-ticket {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.amc-home-ticket .amc-home-ticket-header-icon img {
  width: 200px;
  height: 200px;
  margin-left: 119px;
}

.amc-home-ticket-header-hour {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 32px;
  width: 80%;
}

.amc-home-ticket-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.amc-home-ticket-body p {
  font-size: 18px;
}

/* Etiquta */
.amc-home-label {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.amc-home-label-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amc-logo img {
  height: 145px;
}

.amc-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.amc-home-label-body {
  margin-top: 20px;
}

.amc-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
}

.amc-table td {
  border: 1px solid #000;
  padding: 10px;
}

.amc-table td:first-child {
  width: 40%;
}

/* Exit */
.modal-text {
  font-size: 20px;
  color: #4a4a4a;
  font-weight: 500;
  margin-bottom: 20px;
}

.exit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.exit-button:hover {
  background-color: #0056b3;
}

.alert-text {
  color: red;
  font-size: 12px;
  font-weight: 200;
  font-style: normal;
  line-height: normal;
}

.autocomplete-suggestions {
  list-style: none;
  padding: 0;
  margin: 50px 0 0 0;
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.autocomplete-suggestion {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.autocomplete-suggestion:hover {
  background-color: #f5f5f5;
}

@media print {
  @page {
    margin: 0;
  }

  .page-break {
    page-break-after: always;
  }

  .amc-home-label {
    width: 6in;
    height: 4in;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
  }

  .amc-home-label .amc-home-label-header {
    margin-top: -100px;
    width: 100%;
  }

  .amc-home-label .amc-home-label-body {
    margin-top: -20px;
    width: 100%;
  }

  .amc-home-label .amc-home-label-body .amc-date {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }

  .amc-home-ticket {
    width: 8cm;
    height: 10cm;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
  }

  .amc-home-ticket .amc-home-ticket-header {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .amc-home-ticket .amc-home-ticket-header .amc-home-ticket-header-icon {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .amc-home-ticket .amc-home-ticket-header .amc-home-ticket-header-icon img {
    margin-left: 0;
  }

  .amc-home-ticket .amc-home-ticket-header .amc-home-ticket-header-hour {
    width: 100%;
    height: 50%;
    margin-left: 0;
  }

  .amc-home-ticket .amc-home-ticket-body {
    width: 100%;
    height: 50%;
    font-size: 25px;
  }
}

@media screen and (min-width: 1281px) {
}

@media screen and (min-width: 913px) and (max-width: 1280px) {
  .amc-home-content .amc-domestico-home-content-content {
    width: 75%;
  }

  .amc-home-content .amc-domestico-home-content-buttons {
    width: 25%;
  }
}

/* ============================================================
  TABLETS
  ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .amc-home-content .amc-domestico-home-content-content {
    width: 70%;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-title {
    margin: 5px;
    text-align: center;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-form {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-form
    .amc-domestico-home-content-content-form-title {
    margin-top: -238px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-form
    .amc-domestico-home-content-content-form-inputs {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .amc-home-content .amc-domestico-home-content-buttons {
    width: 30%;
  }
}

/* ============================================================
      PHONE
      ============================================================ */
@media screen and (max-width: 620px) {
  .amc-home-container {
    width: 100%;
    height: 846px;
  }

  .amc-home-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  .amc-home-content .amc-domestico-home-content-content {
    width: 100%;
    height: 90%;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-title {
    margin: 2px;
    text-align: center;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-form {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-form
    .amc-domestico-home-content-content-form-title {
    margin-top: -38px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-form
    .amc-domestico-home-content-content-form-inputs {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .amc-domestico-home-content-content-form-inputs-row {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 100%;
  }

  .amc-domestico-home-content-content-form-inputs-pieces,
  .amc-domestico-home-content-content-form-inputs-destination {
    width: 100%;
  }

  .amc-home-content .amc-domestico-home-content-buttons {
    width: 100%;
    height: 10%;
    justify-content: center;
    align-items: center;
  }

  .amc-home-content .amc-domestico-home-content-buttons .container-btns {
    width: 85%;
  }
}
