.inspector-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.inspector-container .inspector-header {
  width: 100%;
  height: 22%;
  background-color: #020c41;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../../../assets/imgs/amc-domestico/Estelas_AMC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  margin-bottom: 25px;
}

.inspector-header img {
  width: 300px;
}