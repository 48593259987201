.additional-service-content {
    width: 90%;
    height: auto;
    display: flex;
    gap: 15px;
    margin: 40px 0;
}

.additional-service-content .icon-content {
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px;
    background: var(--color-blue-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.4rem;
}

.additional-service-content .data-content h5 {
    color: var(--color-premium-blue);
    font-weight: bold;
    font-size: 1.6rem;
}

.additional-service-content .data-content p {
    font-size: .9rem;
}

.more-information {
    display: flex;
    margin-top: 50px;
    gap: 10px;
    align-items: center;
}

.more-information i {
    font-size: 1.7rem;
    margin-top: -12px;
    color: var(--color-blue-light);
}

.more-information h4 {
    font-size: 2.2rem;
    font-weight: bold;
}

.more-information-container {
    margin-bottom: 50px;
}

.more-information-container p {
    color: #9f9f9f;
    font-size: .9rem;
}

.more-information-container button {
    padding: 15px 30px;
    font-weight: bold;
    border-radius: 25px;
    border: none;
    background: var(--color-azul-innovacion);
    color: #fff;
    transition:  .4s ease all;
}

.more-information-container button:hover {
    background: var(--color-mexican-pink);
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .more-information-container {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .more-information-container button {
        margin: auto;
    }
}
