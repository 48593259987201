thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.table-responsive {
  max-height: 400px;
  overflow-x: scroll;
  overflow-y: scroll;
}

/* Scrollbar vertical */
.table-responsive::-webkit-scrollbar {
  width: 1rem !important;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #020c41;
  border: 0.8rem solid #020c41 !important;
}

/* Scrollbar horizontal */
.table-responsive::-webkit-scrollbar-track {
  background-color: #ffffff; /* Cambia el color del track (fondo) del scrollbar horizontal si lo deseas */
}

.table-responsive::-webkit-scrollbar-thumb:horizontal {
  border: 0.8rem solid #020c41 !important;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.panel-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .colum-left{
    margin-bottom: 10px;
  }
  
  .searchInput{
    margin-right: 20px;
  }
  
  .colum-rigth {
    margin-bottom: 10px;
    margin-right: 40px;
  }
  
  .btnn {
	box-shadow:inset 0px 0px 11px 1px #23395e;
	background:linear-gradient(to bottom, #020c41 5%, #020c41 100%);
	background-color:#2e466e;
	border-radius:37px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	padding:8px 20px;
	text-decoration:none;
}
.btnn:hover {
	background:linear-gradient(to bottom, #415989 5%, #2e466e 100%);
	background-color:#415989;
}
.btnn:active {
	position:relative;
	top:1px;
}

.editButton {
    font-size: 23px;
    font-weight: 600;
    margin-right: 10px;
    color: #0e2240;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}

.editButton:hover {
    transform: scale(1.2);
}

.detailsButton{
    font-size: 23px;
    font-weight: 600;
    margin-right: 10px;
    color: #6b86bf;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}

.detailsButton:hover {
    transform: scale(1.2);
}
.infoButton{
  font-size: 23px;
  font-weight: 600;
  margin-right: 10px;
  color: var(--color-azul-innovacion);
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
}

.infoButton:hover {
  transform: scale(1.2);
}

.deleteButton{
    font-size: 23px;
    font-weight: 600;
    color: #ff0000;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}
.deleteButton:hover {
    transform: scale(1.2);
}

.modalCp .file-input {
  margin-top: 20px;
  width: 20rem;
}

.modalCp .postal-code-list-container {
  margin-top: 10px;
}

.modalCp .postal-code-list-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.modalCp .postal-code-list {
  list-style-type: none;
  padding: 0;
}

.modalCp .postal-code-list li {
  margin-bottom: 5px;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  
}


@media screen and (max-width: 600px) {
  .table-responsive::-webkit-scrollbar {
    width: 1rem !important;
  }
  
  .table-responsive::-webkit-scrollbar-thumb {
    background-color: #020c41;
    border: 10px solid #020c41 !important;
  }
  
  /* Scrollbar horizontal */
  .table-responsive::-webkit-scrollbar-track {
    background-color: #ffffff; /* Cambia el color del track (fondo) del scrollbar horizontal si lo deseas */
  }
  
  .table-responsive::-webkit-scrollbar-thumb:horizontal {
    border: 10px solid #020c41 !important;
  }
  
  .panel-table {
    display: flex;
    flex-direction: column;
  }
  
  .colum-left{
    margin-bottom: 10px;
  }
  
  .searchInput{
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .colum-rigth {
    margin-bottom: 20px;
    margin-left: -90px;
  }
  
  .btnn {
	box-shadow:inset 0px 0px 11px 1px #23395e;
	background:linear-gradient(to bottom, #020c41 5%, #020c41 100%);
	background-color:#2e466e;
	border-radius:37px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	padding:8px 20px;
	text-decoration:none;
}
.btnn:hover {
	background:linear-gradient(to bottom, #415989 5%, #2e466e 100%);
	background-color:#415989;
}
.btnn:active {
	position:relative;
	top:1px;
}
}