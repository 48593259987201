.cont-btn-logger {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.cont-btn-logger button {
    border-radius: 25px;
    padding: 10px 15px;
    border: none;
    background: var(--color-premium-blue);
    color: #fff;
}

.table-preve td a {
    text-decoration: none;
    cursor: pointer;
}