.privacy-intro {
    display: flex;
    gap: 10px;
}

.privacy-intro button {
    border: none;
    background: #fff;
    cursor: pointer;
    font-size: 2rem;
}

.privacy_box{
    position: relative;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.texto_box{
    width: 100%;
    position: relative;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
    overflow-wrap: break-word;
}

.aviso_priv{
    color: #0b2343;
    font-size: 38px;
    margin-top: 0;
    margin-bottom: 0px;
    font-weight: 600;
    position: relative;
}

.box_date{
    width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 45px;
}

.privacy_date{
    color: #999999;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0px;
    position: relative;
}

.ley{
    color: #666;
    font-size: 14px;
    line-height: 1.6;
    position: relative;
    margin-bottom: 25px;
    font-weight: 400;
}

.texto_box h3{
    font-size: 22px;
    color: #0b2343;
    font-weight: 600;
}

.conta_privacy p{
    color: #666;
    font-size: 14px;
    line-height: 1.6;
    position: relative;
    margin-bottom: 25px;
}

.conta_privacy ul li{
    color: #666;
    font-size: 14px;
    line-height: 1.6;
    position: relative;
    margin-bottom: 25px;
}

.pricacySectionPink {
    width: 100%;
    background: var(--color-premium-blue);
    border-top: .5px solid var(--color-mexican-pink);
    background-image: url('../../assets/imgs/track/lines.svg');
    background-repeat: repeat-y;
    background-position: right;
}
.input-privacy {
    width: 250px;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    text-align: center;
    white-space: nowrap;
    background-color: var(--color-premium-blue);
    border: 1px solid #ced4da;
    border-radius: none;
  }
.form-privacy {
    border-radius: none !important;
  }

.border-form {
    border: 1px solid var(--color-premium-blue);
}



/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .texto_box{
        padding: 40px 3px;
    }
  }