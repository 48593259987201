.shift-change-container {
  display: flex;
  flex-direction: column;
}

.current-time {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.current-time .time {
  font-size: 2.5rem;
}

.current-time .text-current {
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.current-time .text {
  font-size: 1.8rem;
  color: var(--color-azul-innovacion);
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.change-time-container {
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.change-time-container .header-time {
  width: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #000;
  padding: 10px;
  background: var(--color-premium-blue);
  color: #FFF;
  cursor: pointer;
}

.change-time-container .header-time p {
  margin: 0;
  padding: 0;
}

.change-time-container .header-time .down-arrow.down {
  font-size: 1.3rem;
  display: flex;
  transition: .4s ease all;
}

.change-time-container .header-time .down-arrow.up {
  font-size: 1.3rem;
  display: flex;
  transform: rotate(180deg);
  transition: .4s ease all;
}

.change-time-container .body-time.active {
  width: auto;
  height: auto;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  transition: max-height 1s ease;
}

.change-time-container .body-time.false {
  width: auto;
  height: auto;
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  overflow: hidden;
  transition: max-height .6s ease;
}

.body-time .instructions:first-child {
  margin-top: 30px;
}

.body-time .instructions {
  width: auto;
  width: 100%;
  text-align: center;
  display: flex;
  margin: 0;
  padding: 0;
}

.body-time .change-time-form {
  width: auto;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}