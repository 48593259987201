.compliance{
    width: 100%;
    position: relative;
    visibility: inherit;
    /* opacity: 1; */
}

.compliance_navigation{
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    position: absolute;
    padding-top: 12px;
    /* padding-bottom: 12px; */
    padding-left: 8.33333%;
    padding-right: 8.33333%;
    border-bottom: 1px solid #e5e5e5;
    background-color: transparent;
}

.compliance_nav_logo{
    width: 100%;
    position: relative;
}

.compliance_nav_logo a{
    display: block;
    position: relative;
    text-decoration: underline;
}

.compliance_nav_logo a img{
    width: 134px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
}

.compliance_menu nav ul{
    width: 100%;
    list-style: none;
    padding: 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.compliance_menu nav ul li{
    position: relative;
    font-weight: 400;
}

.compliance_menu nav ul li a{
    font-weight: 600;
    color: white;
    font-size: 13px;
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.compliance_menu nav ul li a:hover{
    text-decoration: none;
    color: #002e5a;
}

.compliance_menu nav ul li + li{
    margin-left: 35px;
}

.compliance_cover{
    width: 100%;
    height: 80vh;
    z-index: 5;
    position: relative;
    overflow: hidden;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
    background-color: #f7f7f7;
}

.compl_cover_text{
    width: 75%;
    /* width: 50%; */
    top: 50%;
    z-index: 40;
    position: absolute;
    transform: translateY(-50%);
}

.compl_cover_text h1{
    font-size: 36px;
    font-weight: 500;
    position: relative;
    margin-bottom: 25px;
    margin-top: 0;
    color: white;
}

.compl_cover_text p{
    font-size: 16px;
    /* width: 58.33334%; */
    padding: 0px;
    position: relative;
    margin: 0px;
    font-weight: 400;
    color: white;
}

.compl_cover_text button{
    width: auto;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 35px;
    border-radius: 34px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.compl_cover_text button:hover{
    background-color: var(--color-mexican-pink);
}

.compl_cover_text button img{
    width: 12px;
    fill: none;
    stroke: white;
    stroke-width: 1.83058;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    display: inline-block;
    position: relative;
    margin-left: 6px;
    overflow: hidden;
    vertical-align: middle;
    color: white;
}

.compli__cover__filter{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.3);
}

.compli_cover_image{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 15;
    background-position: 0% 25% !important;
}

.bg_img{
    background-size: cover;
    background-image: url(../../assets/imgs/compliance_imgs/fondo_avion.jpg);
}

.bg_img img{
    top: 50%;
    left: 50%;
    border: 0px;
    padding: 0px;
    margin: 0 auto;
    transform: translate(-50%, -50%);
}

.compliance__cover__image img{
    width: 100%;
    height: auto;
    position: relative;
    opacity: 0;
    visibility: hidden;
}

.compliance_conduct{
    width: 100%;
    position: relative;
    padding-bottom: 8em;
}

.comp_conduct_intro{
    width: 100%;
    position: relative;
    padding-top: 6em;
    padding-bottom: 10em;
    text-align: center;
    background-color: #00498f;
}

.comp_cond_conta{
    width: 100%;
    margin: 0 auto;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
    position: relative;
}

.comp_cond_conta img{
    width: 88px;
    height: 88px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    position: relative;
    vertical-align: middle;
}

.comp_conduct_intro h2{
    font-size: 28px;
    width: auto;
    color: white;
    font-weight: 400;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}

.comp_conduct_intro p{
    color: white;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: auto;
    font-weight: 400;
}

.comp_conduct_info{
    width: 75%;
    margin: -4em auto;
    position: relative;
    background-color: #f2f2f2;
}

.comp__conduct_info__image{
    width: auto;
    z-index: 15;
    margin: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    text-align: right;
    display: flex;
    align-content: center;
    justify-content: flex-end;
}

.comp__conduct_info__image img{
    max-width: 60%;
    border: 0px;
    margin: 0px;
    padding: 0px;
    display: block;
    position: relative;
    text-align: right;
}

.comp_conduct_info_conta{
    width: 100%;
    display: block;
    z-index: 20;
    position: relative;
    padding-top: 50px;
    padding-bottom: 40px;
    padding-left: 60px;
    padding-right: 60px;
}

.comp_conduct_info_conta h4{
    font-size: 24px;
    color: #0b2343;
    font-weight: 400;
    margin-bottom: 16px;
    position: relative;
}

.comp_conduct_info_conta p{
    width: 58.33334%;
    font-size: 14px;
    color: #999999;
    margin: 0px;
    position: relative;
}

.comp_conduct_info_conta a{
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    margin-top: 35px;
    position: relative;
    width: auto;
}

.comp_conduct_info_conta a:hover{
    color: white;
    background-color: var(--color-mexican-pink);
}

.comp_conduct_info_conta a img{
    width: 16px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
}
.btn-anticirruption {
    font-family: "Garnett-Regular";
    background-color: white;
    color: var(--color-premium-blue);
    cursor: pointer;
    font-size: 14px;
    margin-top: 35px;
    width: auto;
}

.compliance_ethics{
    display: block;
    width: 100%;
    position: relative;
    z-index: 5;
    overflow: hidden;
    background-color: white;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1pz solid #e5e5e5;
}

.comp_ethics_background{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 15;
    background-image: url(../../assets/imgs/compliance_imgs/back.png);
    background-size: contain;
    background-repeat: repeat;
    background-position: center center;
}

.compl_ethics_container{
    width: 100%;
    margin: 0 auto;
    z-index: 20;
    position: relative;
    padding-top: 6em;
    padding-bottom: 6em;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
}

.compl_ethics_image{
    width: 100%;
    position: relative;
    text-align: center;
}

.compl_ethics_image img{
    width: 60%;
    top: -72px;
    left: 50%;
    margin: 0 auto;
    border: 0px;
    padding: 0px;
    position: absolute;
    transform: translateX(-50%);
}

.compliance_ethics_information h4{
    color: #0b2343;
    font-size: 26px;
    font-weight: 400;
    position: relative;
    margin-bottom: 1rem;
}

.compliance_ethics_information p{
    color: #999999;
    font-size: 14px;
    position: relative;
    margin-bottom: 1rem;
}

.compl_ethics_info_list{
    width: 75%;
    position: relative;
    list-style: none;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin: 0px;
    padding: 0px;
}

.compl_ethics_info_list li{
    width: 50%;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
}

.compl_ethics_info_list li img{
    width: 16px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
}

.compl_ethics_info_list li span{
    color: #999999;
    font-size: 14px;
    position: relative;
}

.compliance_ethics_footer{
    width: 100%;
    position: relative;
    margin-top: 25px;
}

.compliance_ethics_footer_button{
    width: 100%;
    position: relative;
}

.compliance_ethics_footer_button a{
    font-weight: 400;
    font-size: 12px;
    color: white;
    background-color: var(--color-mexican-pink);
    border: 1px solid var(--color-mexican-pink);
    position: relative;
    border-radius: 25px;
    padding: 12px 26px;
    width: auto;
}

.compliance_ethics_footer_button a:hover{
    background-color: var(--color-mexican-pink);
    border: 1px solid var(--color-mexican-pink);
}

.complience_ethics_footer_phone{
    list-style: none;
    width: 100%;
    padding: 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.complience_ethics_footer_phone li{
    position: relative;
}

.complience_ethics_footer_phone li label{
    color: #999999;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 2px;
    display: block;
    padding: 0px;
    position: relative;
}

.complience_ethics_footer_phone li a{
    font-weight: 600;
    color: #0b2343;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.complience_ethics_footer_phone li + li{
    margin-left: 45px;
}

.compliance_ethics_footer_text{
    width: 100%;
    position: relative;
    text-align: center;
    padding-top: 6em;
    padding-bottom: 6em;
    background-color: #f5fafc;
}

.compl_ethics_footer_conta{
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
}

.compl_ethics_footer_logo{
    width: 100%;
    position: relative;
    text-align: center;
    margin-bottom: 12px;
}

.compl_ethics_footer_logo img{
    width: 154px;
    margin: 0 auto;
    display: inline-block;
    border: 0px;
    padding: 0px;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    margin-bottom: 15px;
}

.compl_ethics_footer_logo p{
    font-size: 17px;
    margin-bottom: 30px;
    color: #999999;
    margin-top: 0;
    font-weight: 400;
}

.compliance_ethics_footer_text a img{
    width: 14px;
    fill: none;
    stroke: white;
    stroke-width: 1.83058;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  display: inline-block;
  position: relative;
}

.btn_compliance_show li{
    width: 14px;
    fill: none;
    stroke: white;
    stroke-width: 1.83058;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    display: inline-block;
    position: relative;
    font-size: 1rem;
    list-style: none;
}


.btn_compliance_show{
    display: flex;
    gap: 5px;
}

.btn_compliance_mexico{
    width: 20rem;
}

.btn_compliance_mexico:hover{
    background-color: var(--color-mexican-pink);
}

.btn_compliance_mexico li{
    width: 14px;
  fill: none;
  stroke: white;
  stroke-width: 1.83058;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  display: inline-block;
  position: relative;
    font-size: 1rem;
    list-style: none;
}

.compliance-acl{
    width: 100%;
    position: relative;
    text-align: center;
    margin-bottom: 12px;
}
.compliance-acl p{
    color: #999999;
    font-weight: 400;
}
.bg-compliance-white {
    background-color: #f5fafc;
}
.img-andres{
    width: 80% !important;
    height: auto;
    margin-bottom: 20px;
}
.btn-comunicate {
    width: auto;
    border-radius: 25px;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    font-weight: bold;
    transition: .3s ease all;
    background: var(--color-azul-innovacion);
    color: #ffffff;
    text-decoration: none;
}
.btn-comunicate:hover {
    background: var(--color-mexican-pink);
    color: #fff;
}
.btn-video {
    width: auto;
    border-radius: 25px;
    border: 1px solid #c6c6c6;
    padding: 10px 20px;
    font-size: 1.1rem;
    font-weight: bold;
    transition: .3s ease all;
    background: white;
    color: var(--color-blue-light);
    text-decoration: none;
}
.btn-video:hover {
    border: 1px solid var(--color-blue-light);
}
.divisior{
    width: 85%;
    height: 2px;
    background-color: var(--color-mexican-pink);
    margin: 0 auto;
}
/* ============================================================
PHONE
============================================================ */
@media (min-width: 0px) and (max-width: 768px) {
    .compl_ethics_image img {
      max-width: 50%;
  /*max-width: 40%;*/
      top: -45px;
      left: 0px;
      position: relative;
      transform: translateX(0%);
    }

    .comp_conduct_info_conta h4 {
        font-size: 18px;
        margin-bottom: 12px;
        width: 101%;
      }

      .comp_conduct_info_conta p {
        width: 100%;
        font-size: 12px;
      }

      .comp_conduct_info_conta {
        padding-top: 65px;
        padding-bottom: 105px;
        padding-left: 40px;
        padding-right: 40px;
      }

      .box_ethics_phone{
        margin-top: 25px !important;
      }
  }

  @media screen and (max-width: 346px){
    .comp_conduct_info_conta h4 {
        font-size: 18px;
        margin-bottom: 12px;
        width: 105%;
      }

      .compliance_ethics_information h4{
        font-size: 20px;
      }

      .compliance_ethics_information p{
        font-size: 12px;
      }

      .compl_ethics_info_list{
        width: 115%;
      }

      .compl_ethics_info_list li img{
        width: 14px;
      }

      .compl_ethics_info_list li span{
        font-size: 13px;
      }

      .complience_ethics_footer_phone{
        width: 105%;
      }
  }
  
  @media screen and (max-width: 330px){
    .comp_conduct_intro h2{
        font-size: 22px;
        width: 100%;
    }

    .comp_conduct_intro p{
        font-size: 12px;
        width: 100%;
    }

    .comp_conduct_info_conta h4 {
        font-size: 18px;
        margin-bottom: 12px;
        width: 113%;
      }

      .comp_conduct_info_conta p {
        width: 105%;
        font-size: 12px;
      }

      .compliance_ethics_information h4{
        font-size: 20px;
      }

      .compliance_ethics_information p{
        font-size: 12px;
      }

      .compl_ethics_info_list{
        width: 120%;
      }

      .compl_ethics_info_list li img{
        width: 14px;
      }

      .compl_ethics_info_list li span{
        font-size: 13px;
      }

      .complience_ethics_footer_phone{
        width: 106%;
        margin-left: -5px;
      }

      .btn_compliance_mexico{
        margin-left: -15px;
        width: 19rem;
    }
  }

@media screen and (max-width: 320px) {
    .compl_cover_text h1{
        font-size: 32px;
        margin-bottom: 16px;
    }

    .compl_cover_text p{
        font-size: 14px;
        width: 100%;
    }

    .comp_cond_conta img{
        width: 74px;
        height: 74px;
    }

    .comp_conduct_intro h2{
        font-size: 22px;
        width: 100%;
    }

    .comp_conduct_intro p{
        font-size: 12px;
        width: 100%;
    }

    .comp_conduct_info{
        width: 83.33334%;
    }

    .comp_conduct_info_conta p{
        width: 100%;
        font-size: 12px;
    }

    .comp_conduct_info_conta{
        padding-top: 65px;
        padding-bottom: 105px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .comp_conduct_info_conta h4{
        font-size: 18px;
        margin-bottom: 12px;
        width: 101%;
    }

    .compl_ethics_image img{
        max-width: 50%;
        top: -45px;
        left: 0px;
        position: relative;
        transform: translateX(0%);
    }

    .compliance_ethics_information h4{
        font-size: 20px;
        width: 100%;
    }
    
    .compl_ethics_image{
        margin-bottom: -5px;
    }

    .compliance_ethics_information p{
        font-size: 13px;
        font-weight: 400;
    }

    .compl_ethics_info_list{
        width: 111%;
    }

    .compl_ethics_container{
        padding-bottom: 4em;
    }

    .compliance_ethics_footer_text{
        padding-top: 4em;
        padding-bottom: 4em;
    }

    .compl_ethics_footer_logo p{
        font-size: 14px;
        margin-bottom: 20px;
    }

    .compl_ethics_footer_logo img{
        width: 140px;
    }

    .complience_ethics_footer_phone li{
        width: 50%;
        margin-top: 35px;
    }

    .complience_ethics_footer_phone li +li{
        margin-left: 0px;
    }

    .complience_ethics_footer_phone li a{
        font-size: 13px;
    }

    .btn_compliance_mexico{
        margin-left: -20px;
        width: 19rem;
    }

    .comp_conduct_intro{
        padding-bottom: 8em;
    }
}