.shipment__form__delivery {
  width: 100%;
  position: relative;
  padding-bottom: 10em;
}

.shipment__form__delivery ul {
  width: 100%;
  position: relative;
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.shipment__form__delivery ul li {
  position: relative;
}

.checkbox {
  cursor: pointer;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 15;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.js-shipment-quote-form-oficinas {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.shipment__form__delivery ul li label {
  color: #0b2343;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  display: block;
  position: relative;
  z-index: 5;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.checkbox__label {
  font-family: NeueHaasUnicaPro-Regular, sans-serif;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.checkbox__label span {
  top: 1px;
  line-height: 1;
  margin-top: 0px;
  margin-right: 4px;
  margin-bottom: 0px;
  padding: 0px;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #007cc2;
  background-color: transparent;
}

.checkbox:checked + .checkbox__label span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.checkbox__label span:after {
  content: "";
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  background-color: #007cc2;
}

.checkbox__label data {
  display: inline-block;
  position: relative;
}

.checkbox__label data:hover {
  color: #e91b2f;
}

.is-disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.js-shipment-quote-form-oficinas-text {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  color: #0b2343;
  font-size: 14px;
  text-transform: capitalize;
  width: 100%;
  outline: 0px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  position: relative;
  padding: 14px 16px;
  z-index: 5;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shipment__form__delivery ul li + li {
  margin-top: 30px;
}

.noActive {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.shipment__form__delivery ul li {
  position: relative;
}

.js-shipment-quote-form-domicilio {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.shipment__form__delivery__message__payment {
  position: relative;
  padding-left: 28px;
  margin-bottom: 10px;
  font-size: 14px;
  display: block;
  opacity: 0;
  visibility: hidden;
}

.shipment__form__delivery__message__payment:before {
  content: url('data:image/svg+xml;utf8, <svg width="14px" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23007cc2" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>');
  font-size: 10px;
  top: 50%;
  left: 10px;
  line-height: 1;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}

.shipment__form__delivery__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shipment__form__delivery__text {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  position: relative;
}

.shipment__form__delivery__text span {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  color: #0b2343;
  display: inline-block;
  position: relative;
  padding-right: 16px;
}

.shipment__form__delivery__input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}

.js-shipment-quote-form-domicilio-text {
  width: 50%;
  position: relative;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shipment__form__delivery ul li input[type="number"],
.shipment__form__delivery ul li input[type="text"] {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  color: #0b2343;
  font-size: 14px;
  text-transform: capitalize;
  width: 100%;
  outline: 0px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  position: relative;
  padding: 14px 16px;
  z-index: 5;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shipment__form__delivery__message {
  display: block;
  position: absolute;
  padding: 6px;
  padding-left: 18px;
  margin-top: 10px;
  font-size: 10px;
  opacity: 0;
  visibility: hidden;
}

.shipment__form__delivery__input__loader {
  width: 60px;
  height: 100%;
  top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: calc(50% - 60px);
  position: absolute;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.shipment__form__delivery__input__loader .svg-circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  position: relative;
}

.shipment__form__delivery__input__loader svg {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.is-error {
  color: #e91b2f !important;
  border: 1px solid #e91b2f !important;
  background-color: rgba(234, 28, 47, 0.1) !important;
}

.shipment__form__delivery__message_error::before {
  content: url("data:image/svg+xml;utf8, %3Csvg width='12px' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23EA1C2F' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3E%3C/path%3E%3C/svg%3E");
  font-size: 10px;
  top: 55%;
  left: 0px;
  line-height: 1;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}

.is-success {
  color: #0b2343 !important;
  border: 1px solid #007cc2 !important;
  background-color: #ebf5fa !important;
}

.shipment__form__delivery__message_success::before {
  content: url("data:image/svg+xml,%3Csvg width='12px' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23004a90' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'%3E%3C/path%3E%3C/svg%3E");
  font-size: 10px;
  top: 55%;
  left: 0px;
  line-height: 1;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .shipment__form__delivery {
    width: 36.5rem;
    position: relative;
    padding-bottom: 10em;
  }
}

@media screen and (max-width: 600px) {
  .shipment__form__delivery {
    width: 20rem;
    position: relative;
    padding-bottom: 10em;
  }
}
