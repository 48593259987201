
.modal_compliance_body{
    width: 100%;
    position: relative;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.modal_compliance_body p{
    color: #999999;
    font-size: 12px;
    padding: 0px;
    position: relative;
    margin-bottom: 0px;
}

.modal_compliance_body p + p{
    margin-top: 14px;
}

.modal_compliance_title{
    width: 100%;
    position: relative;
    padding-bottom: 20px;
    z-index: 5;
    overflow: hidden;
    /* background-color: #004A90; */
}

.modal_compliance_title h4{
    color: white;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 10px;
    z-index: 20;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
}

.modal_compliance_title p{
    color: white;
    font-size: 11px;
    margin: 0px;
    position: relative;
    z-index: 20;
    padding-left: 20px;
    padding-right: 20px;
}

.modal_compliance_title_background{
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 15;
}

.modal-header{
    border-bottom: none !important;
    background-image: url(../../assets/imgs/compliance_imgs/modal.png);
}

.modal_compliance_title_background img{
    width: 100%;
    height: auto;
    border: 0px;
    padding: 0px;
    position: relative;
}

.btn_compliance_show_modal{
    display: flex !important;
    gap: 5px;
    background-color: #004A90 !important;
    border-color: #004A90 !important;
}

.btn_compliance_show_modal li{
    width: 14px;
    display: inline-block;
    position: relative;
    font-size: 1rem;
    list-style: none;
}

.btn_compliance_show_modal:hover{
    background-color: var(--color-mexican-pink) !important;
    border-color: var(--color-mexican-pink) !important;
}

.btn_compliance_show_modal_aceptar{
    display: flex !important;
    gap: 5px;
    background-color: #004A90 !important;
    border-color: #004A90 !important;
    border-radius: 34px !important;
    font-size: 12px !important;
    text-transform: uppercase;
}

.btn_compliance_show_modal_aceptar li{
    width: 14px;
    display: inline-block;
    position: relative;
    font-size: 1rem;
    list-style: none;
    font-size: 12px !important;
}

.btn_compliance_show_modal_aceptar:hover{
    background-color: var(--color-mexican-pink) !important;
    border-color: var(--color-mexican-pink) !important;
}


.btn-close{
    color: #fff !important;
    background-color: #ffff !important;
    fill: #ffff !important;
    border: #ffff !important;
}

.modal-footer{
    justify-content: flex-start !important;
}