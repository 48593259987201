thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.table-responsive {
    max-height: 400px;
    overflow-x: auto;
    overflow-y: scroll;
}

.table-responsive::-webkit-scrollbar {
    height: 2rem !important;
  }
  
  .table-responsive::-webkit-scrollbar-thumb {
    border: 0.8rem solid #020c41 !important;
    border-top-width: 0 !important;
  }

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.panel-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan cuando no hay suficiente espacio */
  }
  
  .colum-left{
    margin-bottom: 10px;
  }
  
  .searchInput{
    margin-right: 20px;
  }
  
  .colum-rigth {
    margin-bottom: 10px;
    margin-right: 40px;
  }
  
  .btnn {
	box-shadow:inset 0px 0px 11px 1px #23395e;
	background:linear-gradient(to bottom, #020c41 5%, #020c41 100%);
	background-color:#2e466e;
	border-radius:37px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	padding:8px 20px;
	text-decoration:none;
}
.btnn:hover {
	background:linear-gradient(to bottom, #415989 5%, #2e466e 100%);
	background-color:#415989;
}
.btnn:active {
	position:relative;
	top:1px;
}

  
  @media screen and (max-width: 600px) {
    .panel-table {
      flex-direction: column; /* Cambiar a disposición de columna en pantallas más pequeñas */
    }
  }


.editButton {
    font-size: 23px;
    font-weight: 600;
    margin-right: 10px;
    color: #0e2240;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}

.editButton:hover {
    transform: scale(1.2);
}

.detailsButton{
    font-size: 23px;
    font-weight: 600;
    margin-right: 10px;
    color: #6b86bf;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}

.detailsButton:hover {
    transform: scale(1.2);
}

.deleteButton{
    font-size: 23px;
    font-weight: 600;
    color: #ff0000;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}
.deleteButton:hover {
    transform: scale(1.2);
}

