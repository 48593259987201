.amc-Approved-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  background-color: #ffffff;
}

/* Header */
.amc-Approved-header {
  width: 100%;
  height: 20%;
}

.amc-Approved-header .amc-Approved-header-img {
  background-color: #020c41;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75%;
}

.amc-Approved-header-img .logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
}

.amc-Approved-header-img .logo img {
  width: 300px;
  height: 300px;
}

.amc-Approved-header-img .lines_pink {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  width: 25%;
}

.amc-Approved-header-img .lines_pink img {
  width: 45%;
  height: 100%;
  margin-right: -65px;
}

.amc-Approved-header .amc-Approved-header-hours {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: 15px;
}

.amc-Approved-header-hours .hours {
  background-color: #030d75;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 70%;
  height: 100%;
}

.amc-Approved-header-hours .user {
  background-color: #0000e3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 30%;
  height: 100%;
}

.user .username {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Body */
.amc-Approved-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
}

/* Registros */
.amc-Approved-body .amc-Approved-body-content {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
}

.amc-Approved-body-content .title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 90%;
}

.title p {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  margin-bottom: 0;
}

.amc-Approved-body-content .search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15%;
  width: 90%;
}

.search .inputContainer {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80%;
}

.search .inputContainer span svg {
  font-size: 20px;
  font-weight: bold;
  color: #020c41;
}

.search .inputContainer input {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  color: #737373;
  margin: auto;
}

.amc-Approved-body-content .records {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65%;
  width: 90%;
}

.records-table {
  width: 80%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.table-header {
  background-color: #001f57;
  color: white;
}

.table-header-cell {
  padding: 10px;
  text-align: left;
  border: none;
}

.table-cell {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: white;
}

.highlight {
  background-color: #d3d3d3;
}

.first-cell {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-right: none; /* Quita el borde derecho */
}

.middle-cell {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-left: none; /* Quita el borde izquierdo */
}

.spacing-cell {
  border: none;
  width: 20px;
}

.info-cell {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background-color: white;
  width: 20px;
}

.info-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.info-button {
  background-color: #001f57;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

/* Contador */
.amc-Approved-body .amc-Approved-body-timer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 30%;
  height: 100%;
}

.amc-Approved-body-timer .next-turn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 30%;
}

.amc-Approved-body-timer .time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 30%;
}

.time .countdown {
  padding: auto;
}

.countdown p {
  font-size: 6.5rem;
  font-weight: 200;
  font-style: normal;
  line-height: normal;
  color: #ed544e;
}

.turn-asing {
  font-size: 6.5rem;
  font-weight: 200;
  font-style: normal;
  line-height: normal;
  color: #ed544e;
}

.amc-Approved-body-timer .actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 20px;
  width: 90%;
  height: 30%;
}

.actions .stopped {
  width: 40%;
  height: 40%;
}

.stopped .btn-stop {
  background-color: #ed544e;
  border: none;
  border-radius: 5%;
  width: 90%;
  height: 90%;
}

.btn-stop .FaStop {
  color: #ffffff;
  font-size: 2.5rem;
}

.actions .play_pause {
  width: 40%;
  height: 40%;
}

.play_pause .btn-play_pause {
  background-color: #66c87b;
  border: none;
  border-radius: 5%;
  width: 90%;
  height: 90%;
}

.btn-play_pause .HiMiniPlayPause {
  color: #ffffff;
  font-size: 2.5rem;
}

.amc-Approved-body-timer .acttions-timeOut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 20px;
  width: 90%;
  height: 30%;
}

.acttions-timeOut .finished {
  background-color: #035cf7;
  border: none;
  color: #ffffff;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  width: 80%;
  height: 35%;
}

.acttions-timeOut .vocear {
  background-color: #035cf7;
  border: none;
  color: #ffffff;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  width: 80%;
  height: 35%;
}

.dropdownMenu {
  position: absolute;
  top: 147px;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 460px;
}

.dropdownMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdownMenu ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
  background-color: #123660;
}

.dropdownMenu li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.dropdownMenu .exit-btn {
  background-color: #ed544e;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  height: 40px;
}

/* Modal */
.custom-modal-width {
  max-width: 900px !important;
}

.loading-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.countUp {
  color: #ed544e;
  font-size: 6rem;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.countUp-header {
  color: #ed544e;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.footer-mod {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.btn-resumen {
  width: 85%;
  height: 80%;
  color: #ffffff;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.amc-Approved-body .amc-Approved-body-counters {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
}

.amc-Approved-body .amc-Approved-body-btn {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 20%;
  height: 100%;
}

.counters {
  width: 80%;
  height: 80%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 22px;
  justify-content: center;
  align-items: center;
}

.counter {
  background-color: #020c41;
  border: #ffffff solid 1px;
  height: 80px;
  width: 80px;
  padding: 40px 55px 40px 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: #ffffff;
}

.counter:hover {
  background-color: #1a2767;
  cursor: pointer;
}

.counter-btn-container {
  width: 94%;
  height: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.next-btn {
  padding: 10px;
  width: 80%;
  font-size: 15px;
  font-weight: bold;
  background-color: #035cf7;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.counter.active {
  background-color: #035cf7;
}

@media screen and (min-width: 1281px) {
}

@media screen and (min-width: 913px) and (max-width: 1280px) {
}

/* ============================================================
    TABLETS
    ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  /* Header */
  .amc-Approved-header-img .logo img {
    width: 300px;
    height: 300px;
  }

  .amc-Approved-header-img .lines_pink img {
    width: 34%;
    height: 100%;
    margin-right: 0;
  }

  /* Body */
  .amc-Approved-body {
    flex-direction: column;
  }

  /* Registros */
  .amc-Approved-body .amc-Approved-body-content {
    width: 100%;
    height: 60%;
  }

  .title p {
    font-size: 25px;
  }

  .search .inputContainer {
    width: 85%;
    height: 65%;
  }

  .search .inputContainer input {
    padding: 5px;
  }

  .amc-Approved-body-content .records {
    overflow-y: auto;
  }

  .records-table {
    width: 85%;
  }

  /* Contador */
  .amc-Approved-body .amc-Approved-body-timer {
    width: 100%;
    height: 40%;
  }

  .amc-Approved-body-timer .next-turn {
    height: 20%;
  }

  .amc-Approved-body-timer .time {
    height: 40%;
  }

  .time .countdown {
    padding: auto;
  }

  .countdown p {
    font-size: 5.5rem;
  }

  .amc-Approved-body-timer .actions {
    gap: 20px;
    padding: 10px;
    height: 40%;
  }

  .actions .stopped {
    width: 50%;
    height: 80%;
  }

  .stopped .btn-stop {
    width: 90%;
    height: 90%;
  }

  .btn-stop .FaStop {
    color: #ffffff;
    font-size: 2.5rem;
  }

  .actions .play_pause {
    width: 50%;
    height: 80%;
  }

  .play_pause .btn-play_pause {
    width: 90%;
    height: 90%;
  }

  .btn-play_pause .HiMiniPlayPause {
    color: #ffffff;
    font-size: 2.5rem;
  }

  .amc-Approved-body-timer .acttions-timeOut {
    gap: 20px;
    padding: 10px;
    height: 40%;
    flex-direction: row;
  }

  .acttions-timeOut .finished {
    width: 40%;
    height: 70%;
  }

  .acttions-timeOut .vocear {
    width: 40%;
    height: 70%;
  }

  /* Vista mostrador */
  .amc-Approved-body .amc-Approved-body-counters {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
  }

  .amc-Approved-body .amc-Approved-body-btn {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 10%;
  }

  .counters {
    width: 90%;
    height: 80%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 22px;
    justify-content: center;
    align-items: center;
  }

  .counter-btn-container {
    width: 94%;
    height: 90%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  /* Modal */
  .custom-modal-width {
    max-width: 700px !important;
  }
}

/* ============================================================
        PHONE
        ============================================================ */
@media screen and (max-width: 620px) {
  /* Header */
  .amc-Approved-header-img .logo img {
    width: 300px;
    height: 300px;
  }

  .amc-Approved-header-img .lines_pink img {
    width: 54%;
    height: 100%;
    margin-right: 0;
  }

  .amc-Approved-header .amc-Approved-header-hours {
    flex-direction: column;
    font-size: 14px;
  }

  .amc-Approved-header-hours .hours {
    padding: 6px;
    width: 100%;
    height: 50%;
  }

  .amc-Approved-header-hours .user {
    padding: 0;
    width: 100%;
    height: 50%;
  }

  /* Body */
  .amc-Approved-body {
    flex-direction: column;
  }

  .amc-Approved-body .amc-Approved-body-content {
    width: 100%;
    height: 60%;
  }

  .amc-Approved-body-content .records {
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    height: 65%;
    width: 90%;
  }

  .records-table {
    width: 95%;
  }

  .search .inputContainer {
    width: 95%;
    height: 90%;
  }

  .search .inputContainer input {
    padding: 0px;
  }

  /* Contador */
  .amc-Approved-body .amc-Approved-body-timer {
    width: 100%;
    height: 40%;
  }

  .amc-Approved-body-timer .next-turn {
    height: 20%;
  }

  .amc-Approved-body-timer .time {
    width: 90%;
    height: 30%;
  }

  .time .countdown {
    padding: 0;
  }

  .countdown p {
    font-size: 2.5rem;
  }

  .amc-Approved-body-timer .actions {
    height: 40%;
  }

  .actions .stopped {
    width: 50%;
    height: 100%;
  }

  .stopped .btn-stop {
    width: 90%;
    height: 90%;
  }

  .btn-stop .FaStop {
    font-size: 1.5rem;
  }

  .actions .play_pause {
    width: 50%;
    height: 95%;
  }

  .play_pause .btn-play_pause {
    width: 90%;
    height: 90%;
  }

  .btn-play_pause .HiMiniPlayPause {
    font-size: 1.5rem;
  }

  .amc-Approved-body-timer .acttions-timeOut {
    height: 40%;
    flex-direction: column;
    gap: 10px;
  }

  .acttions-timeOut .finished {
    width: 70%;
    height: 50%;
  }

  .acttions-timeOut .vocear {
    width: 70%;
    height: 50%;
  }

  /* Vista mostrador */
  .amc-Approved-body .amc-Approved-body-counters {
    width: 100%;
    height: 90%;
  }

  .amc-Approved-body .amc-Approved-body-btn {
    gap: 0px;
    width: 100%;
    height: 10%;
  }

  .counters {
    width: 90%;
    height: 80%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 22px;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
  }

  .counter {
    background-color: #020c41;
    border: #ffffff solid 1px;
    height: 80px;
    width: 80px;
    padding: 40px 55px 40px 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    color: #ffffff;
  }

  .counter-btn-container {
    width: 94%;
    height: 90%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}
