/* .titleSection {
    width: 100%;
    background: var(--color-premium-blue);
} */

.container {
    position: relative;
}

.borde{
    border-radius: 5px;
    border: 1px solid #e5e5e5;
}

.arriba{
    padding-top: 12em;
}

.abajo{
    padding-bottom: 12em;
}

.leer_btn{
    width: auto;
    font-weight: 600;
    color: #007cc2;
    font-size: 14px;
    text-transform: capitalize;
    padding: 10px 30px;
    border-radius: 40px;
    border: 1px solid #e5e5e5;
}

.leer_btn:hover{
    color: white;
    /* background-color: #e91b2f; */
    background-color: var(--color-mexican-pink);
}

.nacional, .internacional{
    font-weight: 600;
    font-size: 32px;
    position: relative;
    margin-bottom: 16px;
    box-sizing: border-box;
}

.carga_nac, .carga_inter{
    color: #999999;
    font-size: 15px;
    display: inline-block;
    position: relative;
    margin-top: 0px;
    margin-bottom: 20px;
}