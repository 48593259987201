.iconContainer {
    margin-left: 16px;
}

.icon {
    font-size: 16px;
    color: #007cc2;
}

.text {
    font-size: 16px;
    text-align: justify;
}