/* ======================================================================
TRACK GUIDE FORM
====================================================================== */

.formContainer {
  width: 100%;
  height: auto;
  padding: 20px 20px 10px 20px;
  margin-top: -2px;
  background: #fff;
  border-radius: 0 0 5px 5px;
  border: 2px solid #68b2db;
  z-index: 1;
  border-bottom: 2px solid #68b3db;
}

form {
  margin: 10px 0;
}

.formGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 30px 0;
}

.formGroup input {
  background: none;
  font-size: 0.8rem;
  padding: 10px 10px;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 2px solid #ccc;
  font-weight: bold;
}

.formGroup input:focus {
  border: 2px solid var(--color-mexican-pink);
}

.formGroup input:focus + label,
.formGroup input:not(:placeholder-shown) + label {
  transform: translateY(-18px) scale(0.8);
  transform-origin: left top;
  color: #000;
}

.formGroup label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 20px;
  transform: translateY(10px);
  transition: transform 0.5s #000 0.3s;
  transition: 0.3s ease all;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: #a7a7a7;
}

.alertTrackForm {
  width: 100%;
  color: #c01d5e;
  margin: 0;
  padding: 0;
}

.alertTrackForm p {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 0.8rem;
}

.searchButton {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.searchButton .validClass {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background: var(--color-mexican-pink);
  color: #fff;
  transition: 0.3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchButton .invalidClass {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background: rgb(97, 95, 95);
  color: #fff;
  transition: 0.3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
}

.searchButton .validClass:hover {
  background: var(--color-premium-blue);
  color: #fff;
  border: 1px solid var(--color-premium-blue);
}

.searchButton button p {
  display: block;
  font-weight: bold;
  font-size: 0.9rem;
  width: auto;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.textCont {
  width: 100%;
  height: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.redirecTo {
  font-size: 0.8rem !important;
  text-decoration: underline;
}

.is-disabled {
  opacity: 0.8;
  cursor: not-allowed;
  background-color: #cccccc !important;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .searchButton button p {
    display: block;
    font-weight: bold;
    font-size: 0.8rem;
    width: auto;
    padding: 0;
    margin: 0;
    margin-right: 10px;
  }
  .redirecTo {
    font-size: 0.7rem !important;
  }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
  form {
    margin: 0;
  }

  .formGroup {
    margin: 30px 0;
  }
  .searchButton button {
    width: 100%;
    height: 40px;
    font-size: 1rem;
    border-radius: 5px;
    display: flex;
    margin-bottom: 5px;
  }
  .searchButton button p {
    display: block;
  }
}

/* ======================================================================
QUOTE YOUR SHIPMENT FORM
====================================================================== */

.shipmentFormGroup {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.shipmentFormGroup label {
  width: auto;
  margin-bottom: 5px;
  font-size: 0.8rem;
  color: #a7a7a7;
  font-weight: 700;
}

.shipmentFormGroup label span {
  color: #000;
}

.shipmentFormGroup select {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  font-size: 0.8rem;
  padding: 10px 10px;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 2px solid #ccc;
  font-weight: bold;
  cursor: pointer;
  display: block;
  z-index: 5;
}

.selectContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.selectContainer .iconContainer {
  width: 20px;
  height: 90%;
  border-radius: 4px;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.iconContainer i {
  font-size: 1rem;
  color: var(--color-mexican-pink);
}

.searchButton .btnShipping {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background: var(--color-blue-light);
  color: #fff;
  transition: 0.3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .searchButton .btnShipping {
    width: 100%;
    height: 40px;
    border-radius: 25px;
  }
  .searchButton .btnShipping p {
    display: block;
  }
  .shipmentFormGroup:nth-child(3) {
    margin-bottom: 20px;
  }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
  .shipmentFormGroup {
    margin-bottom: 10px;
  }
  .shipmentFormGroup:nth-child(3) {
    margin-bottom: 20px;
  }
  .shipmentFormGroup label {
    font-size: 0.6rem;
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
  }
  .searchButton .btnShipping {
    width: 100%;
    height: 40px;
    border-radius: 5px;
  }
  .searchButton .btnShipping p {
    display: block;
  }
}
