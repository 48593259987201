.containerImage {
    width: 100%;
    height: auto;
    background: #ececec;
    background-image: url(/public/assets/home/backgroundHeader.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: matrix(1,0,0,1,0,0);
    display: flex;
}

.formsSection {
    width: 38%;
    height: auto;
    min-height: 800px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background: #ffffff;
    order: 1;
}

.formSectionContainer {
    width: 75%;
}

.headerTextCont {
    width: 62%;
    height: auto;
    position: relative;
    order: 2;
}

.contSlider {
    width: 100%;
    height: 450px;
    bottom: 0px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.formsSection h1 {
    width: 75%;
    margin-top: 20px;
    margin-bottom: 40px;
    color: var(--color-blue-hard);
    font-weight: lighter;
    font-size: 2.5rem;
    transition: .5s ease all;
}

.formsSection h1:hover {
    color: var(--color-blue-hard);
}

.formsSection h1 span {
    font-weight: 700;
    white-space: pre-line;
}

.tabs {
    width: 100%;
    display: flex;
    gap: 20px;
    font-size: 1rem;
}

.tabs .tab {
    width: 100%;
    height: 40px;
    background: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    border: 0px solid #ffffff00;
    border-bottom: 2px solid #68b3db;
    cursor: pointer;
    color: #000;
    z-index: 2;
    transition: border-color .3s, color .3s, backgroud .3s;
}

.tabs .tab:hover {
    color: var(--color-mexican-pink);
}

.tab.active { 
    border: 2px solid #68b2db;
    border-bottom: none;
    color: #007CC2;
    background: #ffffff;
    }

.tab p {
    margin: 0;
    padding: 0;
}

/* ============================================================
PC MEDIUM-RESOLUTION
============================================================ */
@media screen and (min-width: 913px) and (max-width: 1425px) {
    .formsSection h1 {
        width: 80%;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 2.5rem;
    }
}

/* ============================================================
PC MIN-RESOLUTION
============================================================ */
@media screen and (min-width: 713px) and (max-width: 1297px) {
    .formsSection h1 {
        width: 90%;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 1.8rem;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 900px) {
    .containerImage {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-image: none;
        background: #ffffff;
    }
    .formsSection {
        width: 100%;
        height: auto;
        min-height: 520px;
        padding-top: 40px;
        padding-bottom: 80px;
        order: 2;
    }
    .formsSection h1 {
        display: none;
    }
    .headerTextCont {
        width: 100%;
        display: flex;
        order: 1;
    }
    .contSlider {
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        overflow: hidden;
    }
    .formSectionContainer {
        width: 100%;
    }

    .tabs {
        font-size: .7rem;
        gap: 10px;
    }
}
