label {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
}

button {
  text-transform: none;
}

p {
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 400;
}

a {
  font-family: "Garnett-Regular", sans-serif;
  color: #0b2343;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}

span {
  font-family: "Garnett-Regular", sans-serif;
}

ul li {
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 400;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

form textarea {
  font-style: normal;
  font-weight: 400;
  color: #212121;
  padding: 0.85rem 0.75rem;
  background-color: white;
}

textarea {
  overflow: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.shipment__section {
  width: 100%;
  position: relative;
}

.js-shipment-quote-form {
  margin: 0;
}

.shipment__layout {
  width: 100%;
  position: relative;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
}

.shipment__form {
  width: 83.33334%;
  padding-top: 4em;
  position: relative;
}

.shipment__form select option {
  z-index: 20;
  position: relative;
}

.shipment__step {
  width: 100%;
  position: relative;
  margin-top: 64px;
  margin-bottom: 25px;
}

.shipment__step h4 {
  font-size: 16px;
  color: #0b2343;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0px;
}

.shipment__step h4 span {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  color: #007cc2;
  text-transform: capitalize;
  display: inline-block;
  margin-right: 14px;
  position: relative;
}

.missing-field {
  border: 1px solid #e91b2f !important;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 35%, 0);
    transform: translate3d(0, 35%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

button.swal2-confirm.custom-confirm-button-class.swal2-styled {
  background-color: #017cc1;
  color: #fff;
  font-weight: bold;
}

.swal2-icon.swal2-warning.swal2-icon-show {
  color: #017cc1;
}

div:where(.swal2-icon).swal2-warning {
  border-color: #017cc1;
  color: #017cc1;
}
