.shipment__form__dimensions__helper {
  width: 100%;
  position: relative;
}

.show_alert {
  display: block;
  opacity: 1;
  visibility: visible;
}

.is-alert-important {
  color: #0b2343;
  border: 0px;
  border-radius: 3px;
  display: inline-block;
  padding: 15px 10px;
  background-color: #ebf5fa;
}

.shipment__form__dimensions {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -64px;
}

.shipment__form__dimensions__count {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  line-height: 1;
  top: 78%;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #004a90;
  display: none;
}

.shipment__form__dimensions .column {
  width: calc(25% - 64px);
  margin-left: 64px;
  position: relative;
  margin-top: 45px;
  margin-bottom: 12px;
}

.shipment__form__dimensions .column label {
  margin-bottom: 10px;
}

.shipment__form__dimensions__number {
  width: 100%;
  position: relative;
}

.shipment__form__dimensions__number input[type="number"],
.shipment__form__dimensions__number input[type="text"] {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  outline: 0px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  position: relative;
  padding: 14px 16px;
  z-index: 5;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shipment__form__dimensions__result {
  width: 100%;
  bottom: -35px;
  padding-top: 12px;
  position: absolute;
  margin-top: 25px;
  margin-left: 80px;
}

.shipment__form__dimensions__result span {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  opacity: 0;
  visibility: hidden;
  display: block;
  position: relative;
}

.visble {
  opacity: 100% !important;
  visibility: visible !important;
}

.shipment__form__dimensions__result i {
  font-style: normal;
}

.shipment__form__dimensions__result data {
  font-family: "Neue Haas Unica W04", sans-serif;
  font-style: normal;
  font-weight: 700;
}

.shipment__form__dimensions__helper p {
  color: #0b2343;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  z-index: 15;
  position: relative;
}

.is-alert-important p {
  color: #0b2343;
  line-height: 1.5;
}

.shipment__form__dimensions__helper p svg {
  fill: #007cc2;
  width: 14px;
  top: -1px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  display: inline-block;
  position: relative;
  margin-right: 4px;
}

.shipment__form__dimensions__helper p strong {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  position: relative;
}

.shipment__form__dimensions__helper p a {
  color: #007cc2;
  display: inline-block;
  text-decoration: underline;
  position: relative;
}

.shipment__form__dimensions__message {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  border-radius: 5px;
  margin-top: 20px;
  background-color: rgba(234, 28, 47, 0.16);
}

.shipment__form__dimensions__message__button {
  top: 8px;
  right: 8px;
  z-index: 20;
  position: absolute;
}

.shipment__form__dimensions__message__button button {
  width: 24px;
  height: 24px;
  display: block;
  border: 0px;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  text-align: center;
  background-color: transparent;
}

.shipment__form__dimensions__message__button button svg {
  width: 10px;
  fill: #e91b2f;
  margin: 0px;
  padding: 0px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}

.shipment__form__dimensions__message p {
  color: #e91b2f;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  z-index: 15;
  position: relative;
}

.shipment__form__dimensions__message p svg {
  fill: #e91b2f;
  width: 12px;
  padding: 0px;
  display: inline-block;
  position: relative;
  margin-right: 4px;
}

.shipment__form__dimensions__elements {
  width: 100%;
  position: relative;
}

.shipment__form__dimensions__file {
  width: calc(100% - 80px);
  position: relative;
  margin-top: 45px;
  margin-bottom: 65px;
  display: none;
  opacity: 0;
  visibility: hidden;
}

.shipment__form__dimensions__file button {
  line-height: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border: 0px;
  padding-left: 0px;
  border-radius: 0px;
  background-color: white;
}

.shipment__form__dimensions__file button svg {
  width: 14px;
  height: 14px;
  fill: #002e5a;
  display: inline-block;
  position: relative;
  margin-right: 6px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shipment__form__dimensions__file button span {
  color: #002e5a;
  font-size: 12px;
  margin: 0px;
  display: inline-block;
  padding: 0px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shipment__form__dimensions__delete {
  cursor: pointer;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  line-height: 1;
  top: 75%;
  right: -5%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  background-color: var(--color-red);
  border-radius: 50%;
  transition: all 0.3s ease-in-out 0s;
}

.shipment__form__dimensions__delete svg {
  width: 8px;
  fill: white;
  display: block;
  top: 50%;
  left: 50%;
  text-align: center;
  position: absolute;
  font-size: 9px;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s ease-in-out 0s;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .shipment__form__dimensions__helper {
    width: 36.5rem;
    position: relative;
  }

  .shipment__form__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    position: relative;
    margin-left: -50px;
    margin-bottom: 30px;
  }

  .shipment__form__column {
    width: 100%;
    position: relative;
    margin-left: 50px;
  }

  .shipment__form__dimensions {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -32px;
  }

  .shipment__form__dimensions .column {
    width: calc(50% - 22px);
    margin-left: 22px;
    position: relative;
    margin-top: 45px;
    margin-bottom: 12px;
  }

  .shipment__form__dimensions__delete {
    top: 86%;
    right: -10%;
  }
}

@media screen and (max-width: 600px) {
  .shipment__form__dimensions__helper {
    width: 20rem;
    position: relative;
  }

  .shipment__form__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    position: relative;
    margin-left: -50px;
    margin-bottom: 30px;
  }

  .shipment__form__column {
    width: 100%;
    position: relative;
    margin-left: 50px;
  }

  .shipment__form__dimensions {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -32px;
  }

  .shipment__form__dimensions .column {
    width: calc(50% - 22px);
    margin-left: 22px;
    position: relative;
    margin-top: 45px;
    margin-bottom: 12px;
  }

  .shipment__form__dimensions__delete {
    top: 86%;
    right: -20%;
  }
}
