/* .height-70 {
    height: 70vh;
} */
.text-clear-gray {
    color: #E3E3E3;
}
.text-clear-gray:hover {
    font-weight: bold;
    color: var(--color-gray-text);
    cursor: pointer;
}
.selected-year {
    font-weight: bold;
    color: var(--color-mexican-pink);
}
.li-none {
    list-style: none;
}
.bg-container{
    height: 70vh;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.bg-1989 {
    background-image: url(../../../assets/imgs/about-us/1989-aeromexico-mexicana.jpg);
}
.bg-1990 {
    background-image: url(../../../assets/imgs/about-us/1990-aeromexico.jpg);
}
.bg-1992 {
    background-image: url(../../../assets/imgs/about-us/1992-expansion-internacional.jpg);
}
.bg-1994 {
    background-image: url(../../../assets/imgs/about-us/1994-IATA.jpg);
}
.bg-1998 {
    background-image: url(../../../assets/imgs/about-us/1998-cargolux.jpg);
}
.bg-2005 {
    background-image: url(../../../assets/imgs/about-us/2005-koreanair.jpg);
}
.bg-2007 {
    background-image: url(../../../assets/imgs/about-us/2007-crecimiento.jpg);
}
.bg-2012 {
    background-image: url(../../../assets/imgs/about-us/2012-aeromexico-cargo.jpg);
}

@media screen and (max-width: 1200px) {
    .bg-container{
        height: 40vh;
    }
}