.weigher-container {
  width: 100%;
  height: 100vh;
}

.weigher-body {
  height: 78%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.weigher-body .weigher-body-title {
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.weigher-body .weigher-body-title h1 {
  font-style: 15px;
  text-align: center;
}

.weigher-body .weigher-body-turns {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.weigher-body .weigher-body-turns-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  gap: 4px;
  padding: 2px;
  width: 70%;
  height: 50%;
}

/* Cards */
.turn-card {
  background-color: #001244;
  color: #ffffff;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0px;
  transition: background-color 0.3s ease;
}

.turn-card:hover {
  background-color: rgba(0, 18, 68, 0.8);
}

.turn-card h1 {
  font-size: 25px;
}

.turn-card h3 {
  font-size: 20px;
}

.turn-card p {
  font-size: 16px;
}

.loading-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Modal */
.custom-modal-width {
  max-width: 900px !important;
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
}

@media screen and (min-width: 913px) and (max-width: 1279px) {
}

/* ============================================================
      TABLETS
      ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .weigher-body .weigher-body-turns-cards {
    width: 95%;
    height: 90%;
  }
}

/* ============================================================
          PHONE
          ============================================================ */
@media screen and (max-width: 620px) {
  .weigher-body .weigher-body-title {
    margin-top: 2rem;
  }

  .weigher-body {
    height: 78%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .weigher-body .weigher-body-title h1 {
    font-style: 15px;
    text-align: center;
  }

  .weigher-body .weigher-body-turns-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 4px;
    padding: 2px;
    width: 95%;
    height: 100%;
  }
}
