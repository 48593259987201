section {
  display: block;
}

.almacen__fiscal__section {
  position: relative;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
  margin-top: 4rem;
}

.almacen__fiscal__title h2 {
  color: #007cc2;
  font-size: 12px;
  position: relative;
  margin-bottom: 0;
  text-transform: capitalize;
}

.almacen__fiscal__information {
  position: relative;
  width: 100%;
  margin-top: 3rem;
}

.almacen__fiscal__information p {
  color: #666666;
  position: relative;
  margin-top: 2rem;
  margin-top: 0;
}

.almacen__fiscal__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  margin-top: 50px;
}

.almacen__fiscal__column {
  width: 50%;
  position: relative;
}

.almacen__fiscal__column__title {
  width: 100%;
  position: relative;
  margin-bottom: 35px;
}

.almacen__fiscal__column__title h4 {
  color: #0b2343;
  font-size: 24px;
  position: relative;
  margin-bottom: 0px;
}

.almacen__fiscal__column__title h4 svg {
  width: 16px;
  height: 20px;
  display: inline-block;
  position: relative;
  margin-right: 6px;
  fill: #007cc2;
}

.almacen__fiscal__column__list {
  width: 100%;
  position: relative;
  padding-left: 8.33333%;
  margin-left: -3rem;
}

.almacen__fiscal__row .almacen__fiscal__column__list ul {
  width: 100%;
  list-style-type: disc;
  list-style: none;
  margin: 0px;
  position: relative;
}

.almacen__fiscal__row .almacen__fiscal__column__list ol li,
.almacen__fiscal__row .almacen__fiscal__column__list ul li {
  color: #666666;
  font-size: 13px;
  position: relative;
  list-style: disc;
}

.almacen__fiscal__row .almacen__fiscal__column__list ul li {
  font-family: NeueHaasUnicaPro-Regular, sans-serif;
  font-weight: 400;
}

.almacen__fiscal__row .almacen__fiscal__column__list li {
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: disc;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .almacen__fiscal__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    margin-top: 50px;
  }

  .almacen__fiscal__column {
    width: 100%;
    margin-bottom: 35px;
  }

  .almacen__fiscal__column__title {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }

  .almacen__fiscal__column__list {
    width: 100%;
    position: relative;
    padding-left: 8.33333%;
    margin-left: -5rem;
  }
}

@media screen and (max-width: 600px) {
  .almacen__fiscal__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    margin-top: 50px;
  }

  .almacen__fiscal__column {
    width: 100%;
    margin-bottom: 35px;
  }

  .almacen__fiscal__column__title {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }

  .almacen__fiscal__column__list {
    width: 100%;
    position: relative;
    padding-left: 8.33333%;
    margin-left: -3rem;
  }
}

.almacen__fiscal__column__footer {
  width: 100%;
  position: relative;
  padding-bottom: 2em;
}

.almacen__fiscal__items {
  width: 100%;
  position: relative;
}

.almacen__fiscal__item__title {
  width: 100%;
  position: relative;
  padding-left: 4%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.almacen__fiscal__item__title h4 {
  color: #0b2343;
  font-size: 18px;
  position: relative;
  margin-bottom: 0px;
}

.almacen__fiscal__item__title h4 svg {
  width: 16px;
  fill: #007cc2;
  display: inline-block;
  position: relative;
  padding: 0px;
  margin-right: 6px;
}

.almacen__fiscal__item__information {
  width: 100%;
  position: relative;
  padding-top: 65px;
  padding-bottom: 30px;
  padding-left: 4%;
  padding-right: 4%;
}

.almacen__fiscal__item__information em {
  font-size: 14px;
  color: #007cc2;
  display: inline-block;
  position: relative;
  margin-bottom: 16px;
}

.almacen__fiscal__item__information ul {
  width: 100%;
  position: relative;
  list-style: none;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 0px;
  margin-top: 16px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 40px;
}

.almacen__fiscal__item__information ul li {
  font-family: NeueHaasUnicaPro-Regular, sans-serif;
  font-weight: 400;
}

.almacen__fiscal__item__information ul ol {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.almacen__fiscal__item__information ol {
  display: block;
  list-style-type: decimal;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.almacen__fiscal__item__buttons {
  width: 100%;
  position: relative;
  margin-top: 0px;
  margin-bottom: 65px;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
}

.almacen__fiscal__item__buttons a {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: var(--color-azul-innovacion);
}
.almacen__fiscal__item__buttons a:hover {
  background-color: var(--color-mexican-pink);
}

.button-primary {
  text-transform: uppercase !important;
  border-radius: 34px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.button {
  border: 1px solid transparent;
  text-decoration: none;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
}

@media screen and (max-width: 600px) {
}

.almacen__fiscal__item__footer {
  width: 100%;
  position: relative;
  padding-top: 65px;
  padding-bottom: 65px;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
  background-color: #f5fafc;
}

.almacen__fiscal__item__footer__title {
  width: 100%;
  position: relative;
}

.almacen__fiscal__item__footer__title h4 {
  color: #0b2343;
  font-size: 20px;
  position: relative;
  margin-bottom: 6px;
}

.almacen__fiscal__item__footer__title h5 {
  color: #007cc2;
  font-size: 20px;
  position: relative;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.almacen__fiscal__item__footer__information {
  width: 100%;
  position: relative;
}

.almacen__fiscal__item__footer__information p {
  color: #666;
  font-size: 13px;
  position: relative;
  margin-bottom: 0px;
}

.almacen__fiscal__item__footer__information p + p {
  margin-top: 30px;
}
