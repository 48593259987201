.station__national__section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 45rem;
}

.ql-editor {
  padding: 0px;
  padding-bottom: 10px;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station__national__section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .station__national__section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
  }
}

.station__national__sidebar {
  width: 40%;
  height: auto;
  position: relative;
}

.station__sidebar__title_nac {
  position: relative;
  margin-left: 6rem;
  padding-top: 6rem;
}

.station__sidebar__title_nac h2 {
  color: #0b2343;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0%;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station__national__sidebar {
    width: 100%;
  }

  .station__sidebar__title_nac {
    position: absolute;
    margin-left: 3rem;
    padding-top: 4em;
  }

  .station__sidebar__title_nac h2 {
    color: #0b2343;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0%;
  }
}

@media screen and (max-width: 600px) {
  .station__national__sidebar {
    width: 100%;
  }

  .station__sidebar__title_nac {
    position: relative;
    margin-left: 2rem;
    padding-top: 4em;
    width: auto;
  }

  .station__sidebar__title_nac h2 {
    color: #0b2343;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0%;
  }
}

.station_nationalas_select {
  margin: 3.5rem 0 1rem 6rem;
}

.select_station {
  width: 25rem !important;
  border-radius: 0% !important;
  font-weight: bold !important;
  top: auto;
  bottom: 100%;
}

.hidden {
  display: none;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station_nationalas_select {
    margin: 10rem auto 3rem 3rem;
  }

  .select_station {
    width: 40rem !important;
    border-radius: 0% !important;
    font-weight: bold !important;
    top: auto;
    bottom: 100%;
  }
}

@media screen and (max-width: 600px) {
  .station_nationalas_select {
    margin: 3rem auto 3rem 2rem;
  }

  .select_station {
    width: 20rem !important;
    border-radius: 0% !important;
    font-weight: bold !important;
    top: auto;
    bottom: 100%;
  }
}

.select_station option {
  color: #999999 !important;
  font-weight: bold;
}

.station__national__direction {
  background-color: #0b2343;
  margin: 4rem auto 3rem 6rem;
  width: 25rem;
  border-radius: 2%;
}

.station__national__direction__container {
  width: 100%;
  height: auto;
}

.station__national__direction__container h4 {
  color: #ffff;
  padding-top: 10px;
  margin-left: 15px;
}

.station__national__direction__container label {
  color: #ffff;
  font-size: 13px;
  font-weight: 600;
  margin-left: 15px;
}

.station__national__direction__container p {
  color: #ffff;
  font-size: 10px;
  font-weight: 600;
  margin-top: 0.1rem;
  margin-left: 15px;
}

.station__national__direction__row {
  display: flex;
  justify-content: space-between;
}

.is-left {
  margin-left: 1rem;
  width: 9rem;
}

.is-left label {
  margin-left: 0;
}

.is-left a {
  font-size: 9px;
  text-decoration: none;
  color: #ffff;
  pointer-events: none;
  cursor: auto;
}

.is-right {
  margin-right: 20px;
}

.is-right p label {
  text-align: left;
}

.js-station-subtitle-text {
  font-size: 20px;
}

.js-station-title-text {
  font-size: 18px;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station__national__direction {
    background-color: #0b2343;
    margin-top: 4rem;
    margin-left: 3rem;
    width: 40rem;
    border-radius: 2%;
  }

  .station__national__direction__container h4 {
    color: #ffff;
    padding-top: 10px;
    margin-left: 15px;
  }

  .station__national__direction__container label {
    color: #ffff;
    font-size: 15px;
    margin-left: 15px;
  }

  .description {
    color: #ffff;
    font-size: 13px !important;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-left: 10px;
    width: 25rem;
  }

  .station__national__direction__row {
    display: flex;
    justify-content: space-between;
  }

  .is-left {
    width: 13rem;
    height: auto;
  }

  .is-left label {
    margin-left: 0;
  }

  .is-left a {
    font-size: 15px;
    text-decoration: none;
    color: #ffff;
    pointer-events: none;
    cursor: auto;
  }

  .is-right {
    margin-right: 13rem;
  }

  .is-right p label {
    text-align: left;
  }

  .js-station-subtitle-text {
    font-size: 20px;
  }

  .js-station-title-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .station__national__direction {
    background-color: #0b2343;
    margin-top: 3rem;
    margin-left: 2rem;
    width: 20rem;
    border-radius: 2%;
    height: auto;
  }

  .station__national__direction__container h4 {
    color: #ffff;
    margin-left: 10px;
  }

  .station__national__direction__container label {
    color: #ffff;
    font-size: 13px;
    margin-left: 10px;
  }

  .station__national__direction__container p {
    color: #ffff;
    font-size: 10px;
    margin-top: 0.1rem;
    margin-left: 10px;
  }

  .station__national__direction__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .station__national__direction__row > * {
    margin-bottom: 10px;
  }

  .is-left {
    margin-left: 10px;
    width: 13rem;
  }

  .is-left label {
    margin-left: 0;
  }

  .is-left a {
    font-size: 13px;
    text-decoration: none;
    color: #ffff;
    pointer-events: none;
    cursor: auto;
  }

  .is-right {
    width: 70%;
  }

  .is-right p label {
    text-align: left;
  }

  .js-station-subtitle-text {
    font-size: 20px;
  }

  .js-station-title-text {
    font-size: 18px;
  }
}

.station__national__maps {
  background-color: #f2f2f2;
  width: 60%;
  height: auto;
}

.mapMexico {
  width: 90%;
  height: 90%;
  position: relative;
  left: 5%;
  top: 5%;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station__national__maps {
    background-color: #f2f2f2;
    width: 100%;
    height: auto !important;
  }

  .mapMexico {
    width: 80%;
    height: auto;
    position: relative;
    left: 7%;
    top: 1%;
    bottom: 10%;
  }
}

@media screen and (max-width: 600px) {
  .station__national__maps {
    background-color: #f2f2f2;
    width: 100%;
    height: auto !important;
  }

  .mapMexico {
    width: 80%;
    height: 18rem;
    position: relative;
    left: 7%;
    top: -15%;
    bottom: 10%;
  }
}

.is-active {
  display: block;
  animation: scale-up-center 1s infinite;
}
@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.no-active {
  display: none;
}
