.menu-admin {
    width: 200px;
    height: calc(100vh - 100px);
    overflow: auto;
    padding: 1rem .5rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 20px 1px;
    font-size: 14px;
}

.menu-icon-open-close {
    display: none;
}
.admin-profile-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.admin-profile-btn {
    background-color: #fff;
    border: none;
}
.admin-profile-role {
    color: #2c7abf;
}
.menu-admin-item {
    cursor: pointer;
    border-radius: 10px;
    padding: 8px;
}
.menu-admin-item:hover {
    background-color: #eeeeee;
}
.menu-admin-item-sesion {
    cursor: pointer;
    border-radius: 10px;
    padding: 8px;
}
.text-menu-item{
    color: var(--color-premium-blue);
    text-decoration: none;
    display: flex;
    align-content: center;
}
.menu-item-content {
    width: 100%;
    min-width: 100%;
    height: auto;
    padding: 1.5rem;
}
.main-panel {
    border: solid 1px var(--color-premium-blue);
    border-radius: 5px;
    height: auto;
    width: calc(100% - 200px);
}
.main-panel-name {
    height: 50px;
    background-color: var(--color-premium-blue);
    color: #fff;
    padding: 0 1rem;
    width: 100%;
}
.main-panel-body {
    padding: 2rem 1rem;
    width: 100%;
}



@media screen and (max-width: 1024px) {
    .menu-admin {
        display: none;
        flex-direction: column;
        width: 100%;
        height: auto;
        position: absolute;
        right: -100%;
        opacity: 1;
        transform: translateX(100%);
        transition: all 1s ease;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        padding-bottom: 15px;
    }
    .menu-admin.active {
        display: flex;
        padding-top: 10px;
        right: 0;
        opacity: 1;
        transform: translateX(0);
        z-index: 1;
    }
    .menu-icon-open-close {
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        top: 22px;
        right: 5%;
        font-size: 1.8rem;
        cursor: pointer;   
    }
    .menu-admin-item {
        cursor: pointer;
        border-radius: 10px;
        padding: 8px 14px;
    }
  }