.shipment__form__servicios {
  width: 100%;
  position: relative;
  margin-bottom: 85px;
}

.shipment__form__servicios label {
  font-size: 12px;
  display: block;
  margin-bottom: 14px;
  position: relative;
}

.shipment__form__servicios label i {
  color: #e91b2f;
  font-style: normal;
  display: inline-block;
  position: relative;
  margin-right: 4px;
}

.shipment__form__servicios__row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  margin-left: -85px;
}

.shipment__form__servicios__row .colum {
  width: calc(50% - 85px);
  margin-left: 85px;
  position: relative;
}

.select {
  color: #0b2343;
  cursor: pointer;
  width: 100%;
  position: relative;
  background-color: white;
}

.select::after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #e91b2f;
  content: "";
  right: 14px;
  display: block;
  pointer-events: none;
  position: absolute;
  top: 50%;
  z-index: 20;
  transform: translateY(-50%);
}

.select select {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  cursor: pointer;
  color: #0b2343;
  width: 100%;
  border-radius: 2px;
  position: relative;
  padding: 14px 16px;
  appearance: none;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.shipment__form select option {
  z-index: 20;
  position: relative;
  color: #0b2343;
}

.js-shipment-quote-form-tipo-servicio {
  z-index: 15;
  font-size: 14px;
  border: 1px solid #e5e5e5;
}

.shipment__form__column__message__services {
  width: 100%;
  left: 0px;
  line-height: 1;
  color: #e91b2f;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: none;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(234, 28, 47, 0.16);
}

.shipment__form__column__message__description_services {
  width: 100%;
  left: 0px;
  line-height: 16px;
  color: #fff;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  position: relative;
  padding: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: none;
  opacity: 0;
  visibility: hidden;
  background-color: var(--color-blue-light);
}

.show_alert {
  display: block;
  opacity: 1;
  visibility: visible;
}

.shipment__form__column__message {
  width: 100%;
  left: 0px;
  line-height: 1;
  color: #e91b2f;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: none;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(234, 28, 47, 0.16);
}

.js-shipment-quote-form-codigo-servicio {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  outline: 0px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  background-color: white;
  position: relative;
  padding: 14px 16px;
}

.js-shipment-quote-form-tipo-servicio-extra {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.show_Extra_Service {
  display: block;
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .shipment__form__servicios__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    margin-left: -85px;
  }

  .shipment__form__servicios__row .colum {
    width: 100%;
    margin-left: 85px;
    position: relative;
  }
}

@media screen and (max-width: 600px) {
  .shipment__form__servicios__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    margin-left: -85px;
  }

  .shipment__form__servicios__row .colum {
    width: 90%;
    margin-left: 90px;
    position: relative;
  }
}
