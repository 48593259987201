.cardsContainer {
    width: 100%;
    display: flex;
    gap: 30px;
    overflow-x: auto;
    padding: 20px;
    vertical-align: top;
    flex-wrap: nowrap;
    height: auto;
    background: none;
    margin: 100px 0;
    scroll-behavior: smooth;
    transform: translateX(0);
    transition: transform .5s;
}

.cardsContainer::-webkit-scrollbar {
    display: none;
}

.targetContainer {
    min-width: 400px;
    max-width: 400px;
}

.btnOption {
    width: 30px;
    height: 30px;
    top: 45%;
    border-radius: 50%;
    border: none;
    background: var(--color-blue-light);
    opacity: .5;
    color: #fff;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnOption:hover {
    background: var(--color-blue-hard);
    opacity: 1;
}

.btnLeft {
    position: absolute;
    left: 10px;
}

.btnRight {
    position: absolute;
    right: 10px;
}
