.station__national__section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station__national__section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .station__national__section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
  }
}

.station__national__sidebar {
  width: 40%;
  height: auto;
  position: relative;
}

.station__sidebar__title {
  position: relative;
  margin-left: 6rem;
  padding-top: 6rem;
}

.station__sidebar__title h2 {
  color: #0b2343;
  font-size: 25px;
  font-weight: bold;

  margin-bottom: 0%;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station__national__sidebar {
    width: 100%;
  }

  .station__sidebar__title {
    position: absolute;
    margin-left: 3rem;
    padding-top: 4em;
  }

  .station__sidebar__title h2 {
    color: #0b2343;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0%;
  }
}

@media screen and (max-width: 600px) {
  .station__national__sidebar {
    width: 100%;
  }

  .station__sidebar__title {
    position: relative;
    margin-left: 2rem;
    padding-top: 4em;
    width: auto;
  }

  .station__sidebar__title h2 {
    color: #0b2343;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0%;
  }
}

.station_nationalas_select {
  margin: 3.5rem auto 1rem 6rem;
}

.select_station_inter {
  width: 25rem !important;
  margin-top: 1rem !important;
  border-radius: 0% !important;
  font-weight: bold !important;
  top: auto;
  bottom: 100%;
}

.hidden {
  display: none;
  opacity: 0%;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station_nationalas_select {
    margin: 10rem auto 3rem 3rem;
  }

  .select_station_inter {
    width: 40rem !important;
    border-radius: 0% !important;
    font-weight: bold !important;
    top: auto;
    bottom: 100%;
  }
}

@media screen and (max-width: 600px) {
  .station_nationalas_select {
    margin: 3rem auto 3rem 2rem;
  }

  .select_station_inter {
    width: 20rem !important;
    border-radius: 0% !important;
    font-weight: bold !important;
    top: auto;
    bottom: 100%;
  }
}

.select_station_inter option {
  color: #999999 !important;
  font-weight: bold;
}

.station__national__direction {
  background-color: #0b2343;
  margin: 4rem auto 3rem 6rem;
  width: 25rem;
  border-radius: 2%;
}

.station__national__direction__container {
  width: 100%;
  height: auto;
}

.station__national__direction__container h4 {
  color: #ffff;
  padding-top: 10px;
  margin-left: 15px;
}

.station__national__direction__container label {
  color: #ffff;
  font-size: 13px;
  font-weight: 600;
  margin-left: 15px;
}

.station__national__direction__container p {
  color: #ffff;
  font-size: 10px;
  font-weight: 600;
  margin-top: 0.1rem;
  margin-left: 15px;
}

.station__national__direction__row {
  display: flex;
  justify-content: space-between;
}

.is-left {
  margin-left: 1rem;
  width: 9rem;
}

.is-left label {
  margin-left: 0;
}

.is-left a {
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
  color: #ffff;
  pointer-events: none;
  cursor: auto;
}

.is-right {
  margin-right: 1px;
  width: 18rem;
}

.is-right p label {
  text-align: left;
}

.title-schedule {
  margin-left: 19px !important;
}

.is-right ul {
  font-size: 10px;
  color: #ffffff;
}

.is-right li {
  font-weight: 600;
  list-style: none;
}

.js-station-subtitle-text {
  font-size: 20px;
}

.js-station-title-text {
  font-size: 18px;
}

.noStation {
  width: 20rem !important;
  height: 20rem !important;
}

.noStation h4 {
  width: 20rem;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station__national__direction {
    background-color: #0b2343;
    margin-top: 4rem;
    margin-left: 3rem;
    width: 40rem;
    border-radius: 2%;
  }

  .station__national__direction__container h4 {
    color: #ffff;
    padding-top: 10px;
    margin-left: 15px;
  }

  .station__national__direction__container label {
    color: #ffff;
    font-size: 15px;
    margin-left: 15px;
  }

  .description {
    color: #ffff;
    font-size: 13px !important;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-left: 10px;
    width: 25rem;
  }

  .station__national__direction__row {
    display: flex;
    justify-content: space-between;
  }

  .is-left {
    width: 13rem;
    height: auto;
  }

  .is-left label {
    margin-left: 0;
  }

  .is-left a {
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #ffff;
    pointer-events: none;
    cursor: auto;
  }

  .is-right {
    margin-right: 5rem;
  }

  .is-right p label {
    text-align: left;
  }

  .is-right ul {
    font-size: 10px;
    color: #ffffff;
  }

  .is-right li {
    font-weight: 600;
    list-style: none;
  }

  .js-station-subtitle-text {
    font-size: 20px;
  }

  .js-station-title-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .station__national__direction {
    background-color: #0b2343;
    margin-top: 3rem;
    margin-left: 2rem;
    width: 20rem;
    border-radius: 2%;
    height: auto;
  }

  .station__national__direction__container h4 {
    color: #ffff;
    margin-left: 10px;
  }

  .station__national__direction__container label {
    color: #ffff;
    font-size: 13px;
    margin-left: 10px;
  }

  .station__national__direction__container p {
    color: #ffff;
    font-size: 10px;
    margin-top: 0.1rem;
    margin-left: 10px;
  }

  .station__national__direction__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .station__national__direction__row > * {
    margin-bottom: 10px;
  }

  .is-left {
    margin-left: 10px;
    width: 13rem;
  }

  .is-left label {
    margin-left: 0;
  }

  .is-left a {
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    color: #ffff;
    pointer-events: none;
    cursor: auto;
  }

  .is-right {
    width: 100%;
  }

  .is-right p label {
    text-align: left;
  }

  .is-right ul {
    font-size: 12px;
    color: #ffffff;
  }

  .is-right li {
    font-weight: 600;
    list-style: none;
  }

  .js-station-subtitle-text {
    font-size: 20px;
  }

  .js-station-title-text {
    font-size: 18px;
  }
}

.station__national__maps {
  background-color: #f2f2f2;
  width: 60%;
  height: auto !important;
  padding: 10px 0;
}

.mapWorld {
  width: 90%;
  height: 100%;
  position: relative;
  left: 5%;
  top: 1%;
  fill: #c4c4c4;
  stroke: #c4c4c4;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.5;
  z-index: 15;
  padding: 0px;
}

.is-Active {
  fill: #004a90;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .station__national__maps {
    background-color: #f2f2f2;
    width: 100%;
    height: auto !important;
  }

  .mapWorld {
    width: 80%;
    height: 20rem;
    position: relative;
    left: 6%;
    top: 2%;
    bottom: 10%;
  }
}

@media screen and (max-width: 600px) {
  .station__national__maps {
    background-color: #f2f2f2;
    width: 100%;
    height: 14rem !important;
  }

  .mapWorld {
    width: 85%;
    position: relative;
    left: 3%;
    top: 0%;
    bottom: 9%;
  }
}
