.kpi-reports-form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.kpi-reports-form button {
  margin-top: 30px;
}