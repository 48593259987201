.light-clients-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.clients-body-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.clients-view-container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clients-body-container h3 {
  margin-top: 40px;
  font-size: 1.8rem;
}
.clients-body-container h5 {
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 1.4rem;
}
.clients-right {
  width: 20%;
  height: 100%;
  background: #e5e5e5;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.clients-right button {
  width: 80%;
  min-width: 120px;
  margin-bottom: 30px;
  border: none;
  border-radius: 3px;
  background: var(--color-azul-innovacion);
  color: #fff;
  font-weight: bold;
  padding: 10px;
}

@media print {
  @page {
    margin: 0;
  }

  .page-break {
    page-break-after: always;
  }

  .amc-home-label {
    width: 6in;
    height: 4in;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
  }

  .amc-home-label .amc-home-label-header {
    margin-top: -100px;
    width: 100%;
  }

  .amc-home-label .amc-home-label-body {
    margin-top: -20px;
    width: 100%;
  }

  .amc-home-label .amc-home-label-body .amc-date {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }

  .amc-home-ticket {
    width: 8cm;
    height: 10cm;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
  }

  .amc-home-ticket .amc-home-ticket-header {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .amc-home-ticket .amc-home-ticket-header .amc-home-ticket-header-icon {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .amc-home-ticket .amc-home-ticket-header .amc-home-ticket-header-icon img {
    margin-left: 0;
  }

  .amc-home-ticket .amc-home-ticket-header .amc-home-ticket-header-hour {
    width: 100%;
    height: 50%;
    margin-left: 0;
  }

  .amc-home-ticket .amc-home-ticket-body {
    width: 100%;
    height: 50%;
    font-size: 25px;
  }
}