/* ======================================================================
CARDS
====================================================================== */
.target {
    width: 100%;
    min-height: 270px;
    height: auto;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.titleTarget {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 13px;
}

.titleTarget i {
    font-size: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-blue-light);
}

.titleTarget h3 {
    color: var(--color-blue-hard);
    font-weight: 700;
}

.titleTarget .imgTitle {
    height: 40px;
    margin-bottom: 10px;
}

.targetDescription {
    width: 100%;
    height: auto;
    min-height: 90px;
}

.target .actions {
    display: flex;
    gap: 15px;
    align-items: center;
}

.redirecPageTo {
    width: auto;
    font-size: 1rem;
    color: var(--color-blue-light);
    cursor: pointer;
    transition: .3s ease all;
    white-space: nowrap;
    font-weight: 600!important;
}

.redirecPageTo:hover {
    color: var(--color-mexican-pink);
  }

.primary-btn {
    color: var(--color-blue-light);
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 1025px) {
    .target {
        width: 100%;
        min-height: 260px;
        height: auto;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .titleTarget {
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 13px;
    }
    .targetDescription {
        width: 100%;
        height: auto;
        min-height: 90px;
        margin: 0;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .target {
        width: 100%;
        min-height: none;
        height: auto;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .targetDescription {
        width: 100%;
        height: auto;
        min-height: 70px;
        margin-bottom: 10px;
    }
}
