.trackIndicators {
    width: 100%;
    margin-top: 8rem;
    color: #717171;
    margin-bottom: 3rem;
}

.delta-text {
    color: #717171;
    margin-top: 0px;
}

.redirectWaybill {
    width: 100%;
    height: auto;
    padding-bottom: 3rem;
    display: flex;
}

a.redirectTrack {
    padding: 0px;
    margin: 0;
    display: flex;
}

.redirectWaybill p {
    margin: 0;
    padding: 0;
}
.btnContactFormTrack {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
}


/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
    .delta-text {
        margin-top: 0px;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .delta-text {
        margin-top: 20px;
    }
    .btnContactFormTrack {
        display: none;
    }
}