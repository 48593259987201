.bg-sky{
    height: 70vh;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    background-image: url(../../../assets/imgs/about-us/sky-team/sky-team-cover.jpg);
}
.bg-sky::before {
    content: "";
    position: absolute;
    top: 166px;
    right: 0;
    bottom: 60px;
    left: 0;
    background-color: rgba(11,35,67,0.5);
}
.h-100 {
    height: 100%;
}
.skyteam-info-container {
  width: 50%;
}
.styteam-members {
    background-color: var(--color-secondary);
    color: #fff;
}
.fs-34 {
    font-size: 34px;
}
.icon-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
}
.icon-center img {
    width: 100%;
    max-width: 184px;
}

@media screen and (max-width: 1200px) {
    .skyteam-info-container {
        width: 80%;
    }
}
/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
    .form-container {
        width: 80% !important;
    }
    .skyteam-info-container {
        width: 100%;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .icon-center img {
        width: 50%;
    }
    .skyteam-info-container {
        width: 100%;
    }
}