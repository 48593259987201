.trackNFTitle {
    font-size: 3rem;
    font-weight: bold;
    color: var(--color-premium-blue);
    margin-top: 40px;
}

.trackNFText1 {
    margin-top: 10px;
    margin: 0;
}

.trackNFText2 {
    margin-bottom: 40px;
}

.trackNFBtn {
    margin-bottom: 40px;
    display: flex;
    gap: 10px;
}

.trackNFBtn button {
    border-radius: 25px;
    border: none;
    padding: 10px 20px;
    background: var(--color-azul-innovacion);
    color: #fff;
    font-weight: bold;
    transition: .3s ease all;
}

.trackNFBtn button:hover {
    border-radius: 25px;
    border: none;
    padding: 10px 20px;
    background: var(--color-mexican-pink);
    color: #fff;
    font-weight: bold;
}