@font-face {
  font-family: "Garnett-Regular";
  src: url(../public/fonts/Garnett-Regular.ttf);
}
@font-face {
  font-family: "Garnett-Semibold";
  src: url(../public/fonts/Garnett-Semibold.ttf);
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: 'Garnett-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  width: 100%;
  height: auto;
}

.bg-tooltip {
  --bs-tooltip-bg: #a8a8a8;
  --bs-tooltip-color: var(--color-premium-blue);
}

.bg-tooltip-icon {
  --bs-tooltip-bg: var(--color-premium-blue);
  --bs-tooltip-color: #fff;
  --bs-tooltip-opacity: 1;
  margin-bottom: 10px;
  margin-left: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-regular{
  font-family: 'Garnett-Regular';
}
.font-semibold{
  font-family: 'Garnett-Semibold';
}
.text-premium-blue {
  color: var(--color-premium-blue);
  font-weight: bold;
}
.text-premium-light {
  color: var(--color-premium-blue);
}
.text-mexican-pink {
  color: var(--color-mexican-pink);
  font-weight: bold;
}
.text-gray {
  color: var(--color-gray-text) !important;
}
.text-azul-innovacio {
  color: var(--color-azul-innovacion) !important;
}
.text-contemporary-blue {
  color: var(--color-contemporary-blue) !important;
}
.text-am-blue {
  color: var(--color-am-blue) !important;
}
.text-blue-light {
  color: var(--color-blue-light) !important;
}
.text-gris-premium {
  color: var(--color-gris-premium) !important;
}
.text-alert {
  color: #dc0f38;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-16-res{
  font-size: 16px;
}
.bg-azul-innovacion {
  background-color: var(--color-azul-innovacion);
}
.bg-premium-blue {
  background-color: var(--color-premium-blue);
}
.bg-azul-consistencia {
  background-color: var(--color-azul-consistencia);
}
.bg-blue-hard {
  background-color: var(--color-blue-hard);
}
.bg-blanco-calido {
  background-color: var(--color-blanco-calido);
}
.bg-gris-suave {
  background-color: var(--color-gris-suave);
}

.redirecTo {
  width: auto;
  font-size: 1rem;
  color: var(--color-blue-light);
  cursor: pointer;
  transition: .3s ease all;
}

.redirecTo:hover {
  color: var(--color-mexican-pink);
}

.primary-btn {
  border-radius: 20px;
  padding: 8px 18px;
  border: 1px solid #eee;
  background: none;
  transition: .3s ease all;
  white-space: nowrap;
  font-weight: 600;
  text-decoration: none;
}

.primary-btn:hover {
  border: 1px solid #eee;
  background: var(--color-mexican-pink);
  color: #fff;
  border: 1px solid var(--color-mexican-pink);
  text-decoration: none;
}

.divider {
  height: 1px;
  width: 100%;
  background: #cacaca;
}

.btn-blue {
  width: 150px;
  border-radius: 25px;
  background-color: var(--color-azul-innovacion);
  border: none;
  padding: 10px 25px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: .3s ease all;
}

.btn-blue:hover {
  background: var(--color-mexican-pink);
  color: #fff;  
}

.auto-with {
  width: auto;
}

.border-radius-none{
  border-radius: none !important;
}

:root {
  --color-premium-blue: #020C41;
  --color-mexican-pink: #ff0073;
  --color-azul-innovacion: #035cf7;
  --color-azul-consistencia: #030D75;
  --color-blue-hard: #020C41;
  --color-blue-light: #017cc1;
  --color-blanco-calido: #F2F2F0;
  --color-gris-suave: #E6E6E3;
  --color-gris-premium: #4A3D3B;
  --color-contemporary-blue: #0000E3;
  --color-am-blue: #00295E;
	--color-red: #E91B2F;
	--color-primary: #004A90;
  --color-secondary: #002E5A;
  --color-gray-text: #666;
}

@media screen and (max-width: 1024px) {}
/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .btn-blue {
    width: 100%;
  }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
  .btn-blue {
    width: 100%;
  }
  .fs-16-res{
    font-size: 14px;
  }
}
