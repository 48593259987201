.modal-window-content {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #00000097;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-window-content .modal-window {
  width: auto;
  min-width: 40%;
  height: auto;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.modal-window .header-modal {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #d1d1d1;
}

.modal-window .header-modal .title {
  font-weight: bold;
}

.modal-window .header-modal .close-btn {
  font-weight: bold;
  color: #841c3b;
  font-size: 1.1rem;
  cursor: pointer;
}

.modal-window .body-modal-details {
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.modal-window .body-modal-details p {
  margin: 3px 0;
  padding: 0;
}

.modal-window .body-modal-details p span {
  font-weight: bold;
  margin-right: 5px;
}

.form-shift-update {
  width: 100%;
  height: auto;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-shift-update .input-container-shift {
  display: flex;
  gap: 15px;
  align-items: center;
}

.input-container-shift label {
  width: 130px;
  display: flex;
}

.input-container-shift input {
  display: flex;
  flex: 1;
}

.form-shift-update .btns-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.form-shift-update .btns-container button {
  padding: 10px 15px;
  border-radius: 10px;
}

.form-shift-update .btns-container .cancel-btn {
  background: #9d2121;
  color: #fff;
  border: none;
}

.form-shift-update .btns-container .send-btn {
  background: var(--color-premium-blue);
  color: #fff;
  border: none;
}

td.btns-container-actions {
  height: auto;
}

.btn-action-shift {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: .8rem;
  background: var(--color-premium-blue);
  color: #fff;
}

.table-content-shift {
  width: 100%;
  height: auto;
  overflow: auto;
}

.table-content-shift table {
  width: 100%;
}

.table-content-shift table tr th {
  white-space: nowrap;
}

.shifts-container {
  width: 100%;
  height: auto;
}