
.bg-admin {
    width: 100%;
    height: 100px;
    background-image: url(../../../assets/imgs/dashboard-admin/backtop.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-admin-gradient {
    position: absolute;
    height: 100px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
    pointer-events: none;
}
.logo-amc-admin {
    width: 160px;
    height: auto;
}