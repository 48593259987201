h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

ul li {
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 400;
}

label {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
}

p {
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 400;
}

.cotizacion {
  width: 100%;
  position: relative;
}

.page {
  opacity: 0;
  visibility: hidden;
}

.cotizacion__section {
  width: 100%;
  position: relative;
}

.cotizacion__title {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
}

.cotizacion__title h2 {
  color: #0b2343;
  font-size: 26px;
  position: relative;
  margin-bottom: 0px;
}

.cotizacion__table {
  width: 100%;
  position: relative;
}

.shipment__table__layout {
  width: 100%;
  position: relative;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
}

.cotizacion__table__information {
  width: 100%;
  position: relative;
}

.cotizacion__table__title {
  width: 100%;
  position: relative;
  margin-bottom: 35px;
}

.cotizacion__table__title h4 {
  color: #1b8198;
  font-size: 16px;
  padding: 0px;
  position: relative;
  margin-bottom: 0px;
}

.cotizacion__table__title h4 svg {
  width: 16px;
  fill: #1b8198;
  display: inline-block;
  margin-left: 10px;
  padding: 0px;
  position: relative;
}

.cotizacion__table__component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  position: relative;
  list-style: none;
  margin: 0px;
  padding: 0px;
  border-left: 1px;
  border-bottom: 0px;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.cotizacion__table__component li {
  width: 25%;
  display: inline-block;
  margin: 0px;
  padding: 14px 16px;
  position: relative;
  border-top: 0px;
  border-right: 0px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.cotizacion__table__component li label {
  color: #0b2343;
  font-size: 16px;
  line-height: 1;
  display: block;
  position: relative;
  margin-bottom: 10px;
}

.cotizacion__table__component li label svg {
  width: 14px;
  fill: #517ab6;
  display: inline-block;
  margin: 0px;
  margin-right: 6px;
  margin-bottom: 3px;
  padding: 0px;
  position: relative;
}

.cotizacion__table__component li data,
.cotizacion__table__component li p {
  color: #666;
  font-size: 14px;
  display: block;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.cotizacion__table__notes {
  width: 100%;
  position: relative;
  margin-top: 25px;
}

.cotizacion__table__notes svg {
  width: 14px;
  margin-right: 6px;
}

.is-alert-important svg {
  fill: #007cc2;
  top: -1.5px;
  padding: 0px;
  position: relative;
  display: inline-block;
}

.cotizacion__table__notes p {
  font-size: 12px;
  display: inline-block;
}

.is-alert-important p {
  color: #0b2343;
  line-height: 1.5;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.cotizacion__table__prices {
  width: 100%;
  position: relative;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
  margin-top: 65px;
  margin-bottom: 65px;
}

.cotizacion__table__prices ul {
  width: 100%;
  position: relative;
  list-style: none;
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.cotizacion__table__prices ul li {
  display: block;
  padding: 16px 20px;
  position: relative;
}

.cotizacion__table__prices__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
}

.cotizacion__table__prices__left {
  width: 50%;
  position: relative;
  text-align: left;
}

.cotizacion__table__prices__left span {
  color: #0b2343;
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  position: relative;
}

.cotizacion__table__prices__right {
  width: 50%;
  position: relative;
  text-align: right;
}

.cotizacion__table__prices__right data {
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 400;
  color: #666;
  font-size: 16px;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}

.cotizacion__table__prices ul li + li {
  border-top: 1px solid #e5e5e5;
}

.cotizacion__table__prices ul li {
  display: block;
  padding: 16px 20px;
  position: relative;
}

.cotizacion__table__prices ul li.is-subtotal {
  background-color: #f7f7f7;
}

.cotizacion__table__prices ul li.is-subtotal span {
  color: #1b8198;
  font-size: 18px;
  position: relative;
}

.cotizacion__table__prices ul li.is-subtotal data {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1b8198;
  position: relative;
}

.cotizacion__table__prices ul li.is-iva {
  background-color: #f7f7f7;
}

.cotizacion__table__prices ul li.is-iva span {
  color: #999;
  font-size: 14px;
  line-height: 1;
}

.cotizacion__table__prices ul li.is-iva span svg {
  width: 10px;
  fill: #999;
  top: -2px;
  display: inline-block;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 2px;
  position: relative;
}

.cotizacion__table__prices ul li.is-iva data {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  color: #999;
  font-size: 14px;
}

.cotizacion__table__prices ul li.is-total {
  background-color: #f2f8fc;
}

.cotizacion__table__prices ul li.is-total span {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.cotizacion__table__prices ul li.is-total span svg {
  width: 18px;
  fill: #0b2343;
  display: inline-block;
  padding: 0px;
  position: relative;
  margin-right: 2px;
}

.cotizacion__table__prices ul li.is-total data {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #0b2343;
}

.cotizacion__table__buttons {
  width: 100%;
  position: relative;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
  padding-top: 4em;
  padding-bottom: 4em;
}

.cotizacion__table__buttons__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cotizacion__table__buttons__column__left {
  width: 50%;
  position: relative;
  text-align: left;
}

.cotizacion__table__buttons__column__left a {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-left: 35px;
  padding-right: 35px;
  background-color: #e91b2f;
}

.button-primary {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  border-radius: 34px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #004a90;
}

.button {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  border: 1px solid transparent;
  text-decoration: none;
  width: 17rem;
}

.cotizacion__table__buttons__column__right {
  width: 50%;
  position: relative;
  text-align: right;
}

.cotizacion__table__buttons__column__right a {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  font-size: 15px;
  padding-left: 35px;
  padding-right: 35px;
  position: relative;
  background-color: #007cc2;
  margin-bottom: 10px;
}

.cotizacion__table__buttons__column__right a svg {
  width: 14px;
  fill: white;
  top: 0px;
  margin-right: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline-block;
  padding: 0px;
  position: relative;
}

.cotizacion__table__buttons__column__right form {
  margin: 0px;
  padding: 0px;
  display: inline-block;
  margin-left: 30px;
}

.cotizacion__table__buttons__column__right button {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  background-color: #007cc2;
  margin-top: -8px;
  margin-left: 8px;
}

.cotizacion__table__buttons__column__right button svg {
  width: 14px;
  fill: white;
  top: -2px;
  margin-right: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline-block;
  padding: 0px;
  position: relative;
}

.cotizacion__table__help {
  width: 100%;
  position: relative;
  padding-top: 4em;
  padding-bottom: 6em;
  background-color: white;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
}

.cotizacion__table__help h4 {
  color: #0b2343;
  font-size: 16px;
  position: relative;
  margin-bottom: 4px;
}

.cotizacion__table__help h4 svg {
  width: 14px;
  fill: #0b2343;
  display: inline-block;
  position: relative;
  margin-right: 6px;
}

.cotizacion__table__help p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  position: relative;
  margin-bottom: 0px;
}

.cotizacion__table__help span {
  color: #666;
  font-size: 14px;
  display: block;
  position: relative;
  margin-top: 4px;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .button {
    font-family: "Garnett-Semibold", sans-serif;
    font-weight: 600;
    border: 1px solid transparent;
    text-decoration: none;
    width: 17rem;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 600px) {
  .cotizacion__title {
    display: flex;
    flex-direction: column;
  }

  .cotizacion__title > div {
    margin-bottom: 2em;
  }
  .cotizacion__table__component li {
    width: 50%;
  }

  .cotizacion__table__buttons {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .cotizacion__table__buttons__column__left {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  .cotizacion__table__buttons__column__left a {
    font-size: 15px;
    display: block;
  }

  .cotizacion__table__buttons__column__right {
    width: 100%;
    text-align: center;
  }

  .cotizacion__table__buttons__column__right a {
    font-size: 14px;
    display: block;
  }

  .cotizacion__table__buttons__column__right form {
    width: 100%;
    display: block;
    margin-left: 0px;
    margin-top: 10px !important;
  }

  .cotizacion__table__buttons__column__right button {
    width: 100%;
    font-size: 15px;
    display: block;
    margin-top: 0px;
    margin-left: 0px;
  }

  .button {
    font-family: "Garnett-Semibold", sans-serif;
    font-weight: 600;
    border: 1px solid transparent;
    text-decoration: none;
    width: 100%;
  }
}
