.form-container {
  width: 50% !important;
  margin: 0 auto;
}
.input-info {
  height: 40px;
  color: var(--color-gray-text) !important;
  font-size: 12px !important;
}
.input-message {
  color: var(--color-gray-text) !important;
  font-size: 12px !important;
}

.spinner {
  font-size: 22px;
  -webkit-animation: 1s rotate linear infinite;
  animation: 1s rotate linear infinite;
  -webkit-transform-origin: 50% 50%;
}

.bg-thanks {
  background-image: url(../../assets/imgs/thanks/thanks_banner_amc.jpg);
  background-position: center;
  background-size: cover;
}

.thanksContainerHeight {
  height: 50vh !important;
}

.is-disabled {
  opacity: 0.8;
  cursor: not-allowed;
  background-color: #cccccc !important;
}

@media screen and (max-width: 1200px) {
  .form-container {
    width: 75% !important;
  }
}
/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .form-container {
    width: 80% !important;
  }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
  .form-container {
    width: 95% !important;
  }
}
