.titleSectionPink {
    width: 100%;
    background: var(--color-premium-blue);
    border-top: .5px solid var(--color-mexican-pink);
    background-image: url('../../assets/imgs/track/lines.svg');
    background-repeat: repeat-y;
    background-position: right;
}

.titleContainer {
    display: flex;
    padding: 30px 0;
    gap: 10px;
}

.titleContainer img {
    width: 30px;
}

.titleContainer h2 {
    color: #fff;
    font-weight: 500;
    font-size: 2rem;
}

.titleContainer h1:hover {
    color: #fff;
}

.container {
    position: relative;
}

.containerSecondarySection {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .titleSectionPink {
        width: 100%;
        background: var(--color-premium-blue);
        border-top: .5px solid var(--color-mexican-pink);
        background-image: none;
    }
    .titleContainer img {
        display: none;
    }
    .titleContainer h2 {
        color: #fff;
        font-weight: 500;
        font-size: 1.5rem;
    }
}