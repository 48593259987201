.textContainer {
    width: 100%;
    height: auto;
    max-height: 55vh;
    overflow-y: scroll;
}

.textContainer::-webkit-scrollbar {
    width: 8px;
}

.textContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.textContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

.headerTextField p {
    margin: 0;
    padding: 0;
}

.headerTextField .titleAccordion {
    background: var(--color-premium-blue);
    color: #fff;
    padding: 5px 8px;
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.titleAccordion button {
    margin-right: 18px;
    cursor: pointer;
    background: var(--color-premium-blue);
    border: none;
    color: #fff;
}

.headerTextField {
    width: 100%;
    height: auto;
    margin-bottom: 2px;
}

.bodyTextField {
    width: 100%;
    overflow-y: scroll;
}

.bodyTextField::-webkit-scrollbar {
    display: none;
}

.bodyTextField.open {
    width: 100%;
    max-height: 9000px;
    transition: max-height 0.5s ease-in-out;
}

.bodyTextField.closed {
    width: 100%;
    max-height: 0px;
    transition: max-height 0.5s ease-in-out;
}

.itemTextField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    transition: .3s ease all;
}

.itemTextField:hover {
    background: #eee;
}

.itemTextField .lngTextIcon {
    width: auto;
    display: flex;
    gap: 15px;
}

.lngTextIcon button {
    cursor: pointer;
    color: var(--color-premium-blue);
    margin: 0;
    padding: 2px 6px;
    border-radius: 25px;
    background: #e1e1e1;
    border: none;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease all;
}

.lngTextIcon button:hover {
    background: var(--color-premium-blue);
    color: #fff;
}

.lngTextIcon button span {
    font-size: .9rem;
    margin-right: 5px;
}

.lngTextIcon button img {
    width: 20px;
}

/* ============================================================
MODAL ADMIN
============================================================ */

.bgModalAdmin {
    width: 100%;
    height: 100vh;
    position: absolute;
    background: #0000009c;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.modalAdmin {
    width: 70%;
    height: auto;
    min-height: 500px;
    max-height: 700px;
    overflow-y: scroll;
    background: #fff;
    z-index: 3;
    padding: 0px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}

.modalAdminHeader {
    width: 100%;
    height: auto;
    display: flex;
    padding: 5px 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #bebebe;
    background: #bebebe;
    margin-bottom: 10px;
}

.modalAdminHeader h3 {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.modalAdminHeader button {
    border: none;
    background: none;
    color: var(--color-premium-blue);
    font-size: 1.5rem;
}

.modalAdminBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
}

.itemModalAdmin {
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid #bebebe;
    padding: 0px 20px;
}

.itemModalAdmin:last-child {
    border: none;
}

.itemModalAdmin .buttonsEditor {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.itemModalAdmin .buttonsEditor button {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 3px 10px;
    margin: 0;
    background: var(--color-premium-blue);
    color: #fff;
    border-radius: 5px;
}

.modalAdminFooter {
    width: 100%;
    background: #bebebe;
    padding: 15px 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

.modalAdminFooter button {
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
}

.modalAdminFooter button:last-child {
    background: var(--color-premium-blue);
    color: #fff;
}

.modalAdminFooter button:first-child {
    background: #dc3741;
    color: #fff;
}