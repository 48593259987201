.universalDetails {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
}

.universalDetailsMb {
    display: none;
}

.status-container-track {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.status-container-track .titleStatus h3 {
    display: flex;
    margin: 0;
    padding: 0;
    color: var(--color-premium-blue);
}

.status-container-track .btnsStatus {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.status-container-track .btnsStatus button {
    border: 1px solid var(--color-mexican-pink);
    background: var(--color-mexican-pink);
    padding: 5px 15px;
    border-radius: 5px;
    font-size: .8rem;
    font-weight: bold;
    transition: .3s ease all;
    color: #fff;
}

.status-container-track .btnsStatus button:hover {
    background: none;
    color: var(--color-premium-blue);
    border: 1px solid var(--color-premium-blue);
}

.status-container-track .btnWaybill {
    color: var(--color-premium-blue);
}

.status-container-track button.btnPrint {
    background: var(--color-mexican-pink);
    color: #fff;
}

/* ============================================================
STATUS  DESKTOP
============================================================ */
.containerDetails {
    width: 100%;
    background: #eee;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.trackBox p, .detailsBox p {
    width: auto;
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-align: center;
    color: var(--color-premium-blue);
}

.waibillNumberBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px;
    border-bottom: 1px solid #828282;
}

.detailsBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrDepContainer {
    width: 50%;
    display: flex;
    border-right: 1px solid #828282;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 25px;
}

.arrDeplBox {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.detailsPieces {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
}

.statusBox {
    width: 100%;
    height: auto;
    display: flex;
    background: #fff;
    align-items: flex-start;
    justify-content: space-between;
}

.statusBox .statusText p, .statusBox .statusType p{
    margin: 0;
    padding: 0 20px;
    text-align: center;
}

.statusBox .statusText {
    width: auto;
    height: 58px;
    min-width: 35%;
    font-size: 1.1rem;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusBox .statusType {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 1.1rem;
}

.statusBox .statusType .type {
    width: 100%;
    height: 58px;
    background: var(--color-premium-blue);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.btnInfoDetails {
    width: 100%;
    height: auto;
    background: #ffffff;
    display: flex;
    padding-top: 40px;
    padding-left: 25px;
}

.infoTextDetails {
    margin-top: 200px;
    color: #828282;
    font-size: .8rem;
}

.infoTextDetails p {
    margin: 0;
    padding: 0;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 990px) {
    .btnInfoDetails {
        display: none;
    }
    .infoTextDetails {
        display: none;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .universalDetails {
        display: none;
    }
    .universalDetailsMb {
        display: block;
        margin-top: 20px;
        padding: 0 10px;
        position: relative;
    }
    .status-container-track {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 10px;
    }
    .status-container-track .btnsStatus {
        display: block;
    }
    .containerDetails {
        background: #eee;
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: auto;
        position: relative;
        z-index: 10;
    }
    .trackBox {
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .trackBox p {
        font-size: .7rem;
        text-align: center;
        margin: 0;
        padding: 0;
    }
    .trackAccordion {
        border: none;
        cursor: pointer;
        color: #9c9c9c;
    }
    .trackContainerBoxMbl.open {
        width: 100%;
        height: 40px;
        max-height: 200px;
        transition: padding .4s ease;
        transition: all .4s ease;
        margin: 5px 0;
        position: relative;
        z-index: 1;
    }
    .trackContainerBoxMbl.closed {
        width: 100%;
        height: 1px;
        max-height: 1px;
        transition: padding .4s ease;
        transition: all .4s ease;
        overflow: hidden;
        margin-top: -30px;
        position: relative;
        z-index: 1;
    }
    .trackBoxMbl {
        background: #eee;
        padding: 10px 0;
        border-left: 1px solid #9c9c9c;
    }
    .trackBoxMbl:first-child {
        border: none;
    }
    .trackBoxMbl p.boldText {
        font-weight: bold;
        text-align: center;
        font-size: .7rem;
        margin-bottom: 1px;
        color: var(--color-premium-blue);
    }
    
    .trackBoxMbl p {
        font-size: .8rem;
        text-align: center;
        margin: 0;
        padding: 0;
    }
}
  