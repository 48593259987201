.container-success-track {
    display: flex;
    gap: 50px;
    width: 100%;
    overflow: auto;
}

.container-success-track .details {
    width: 50%;
}

.container-success-track .status {
    width: 50%;
}

/* ============================================================
TABLETS <->
============================================================ */
@media screen and (min-width: 601px) and (max-width: 990px) {
    .container-success-track {
        display: flex;
        flex-direction: column;
        gap: 1px;
    }
    .container-success-track .details {
        width: 100%;
    }
    
    .container-success-track .status {
        width: 100%;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .container-success-track {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .container-success-track .details {
        width: 100%;
    }
    
    .container-success-track .status {
        width: 100%;
    }
}
