.title-h1 {
    font-size: 34px;
    font-weight: bold;
    color: var(--color-premium-blue);
}
.title-h3 {
    font-size: 18px;
    font-weight: bold;
    color: #007CC2;
}
.icons {
    width: 45px;
    height: auto;
}
.article-container article ul li {
    font-size: 14px;
}
.article-container{
    border: 1px solid #e5e5e5;
}
.article-container article{
    border-bottom: 1px solid #e5e5e5;
}
.icon-container {
    padding: 2rem;
    border-right: 1px solid #e5e5e5;
}

@media screen and (max-width: 767px) {
    .icon-container {
        padding-bottom: 0;
        border-right: none;
    }
}

