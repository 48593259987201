.menuRightContainer {
    width: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;
}
.menuRightContainer li {
    width: 140px;
    font-size: 11px;
    color: var(--color-blue-hard);
    list-style: none;
    margin: 0;
    padding: 8px 10px;
    cursor: pointer;
    transition: .3s ease all;
    position: relative;
}

.menuRightContainer .menuRight {
    width: auto;
    min-width: 150px;
    height: auto;
    background: #ffffff;
    position: absolute;
    right: -150px;
    margin: 0;
    padding: 0;
}

.menuRightContainer .menuRight ul {
    margin: 0;
    padding: 0;
}

.menuRightContainer .menuRight ul li {
    width: 100%;
    min-width: 150px;
    font-size: 11px;
    color: var(--color-blue-hard);
    margin: 0;
    padding: 8px 10px;
    border-bottom: 1px solid #eee;
    transition: .3s ease all;
    white-space: nowrap;
}

.menuRightContainer .menuRight ul li:hover {
    color: #007CC2;
}