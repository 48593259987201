.bg-alert {
    background-color: #ebf5fa;
}
.alert-info {
    color: var(--color-premium-blue);
}
.title-alert {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--color-premium-blue);
}
.date-alert {
    font-size: 12px;
    color: #999;
}
.btn-alert {
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    background-color: white;
    font-size: 15px;
    font-weight: 600;
    color: var(--color-azul-innovacion);
    text-decoration: none;
    padding: 10px 30px;
}
.btn-alert:hover {
    background-color: var(--color-mexican-pink);
    color: white;
}
.notice-conteiner {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.09);
    overflow-wrap: break-word;
}
.btn-return {
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    background-color: var(--color-azul-innovacion);
    font-size: 15px;
    font-weight: 600;
    color: white;
    text-decoration: none;
    padding: 10px 30px;
}
.btn-return:hover {
    background-color: var(--color-mexican-pink);
}
