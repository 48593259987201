.schedule { 
    font-size: 1rem;
    font-weight: bold;
}

.schedule p .no-schedule {
    display: none;
    visibility: hidden;
    opacity: 0%;
}

.schedule .ql-editor {
    font-size: .7rem;
}