.userNFContainer {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #767676;
}

.userNFContainer button {
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    background: var(--color-azul-innovacion);
    color: #fff;
    font-weight: bold;
}