.imgHeaderContainer {
    position: relative;
    width: 100%;
    min-height: 75vh;
    height: auto;
    background: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding: 3rem 0;
}

.imgHeaderContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 27, 27, 0.507);
}

.textImgHeader {
    color: #ffffff;
    display: flex;
    flex-direction: column;
}

.textImgHeader h5{
    font-size: 1.2rem;
}

.textImgHeader h2 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.textImgHeader .description p {
    margin: 0;
    padding: 0;
    line-height: 25px;
}

.textImgHeader .description, .textImgHeader .features {
    width: 55%;
    font-size: .9rem;
}

.textImgHeader .features p {
    font-weight: 600;
    font-size: 1rem;
    margin-top: 30px;
    margin-bottom: 5px;
}

.textImgHeader .btnsContainer {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.textImgHeader button {
    width: auto;
    border-radius: 25px;
    border: none;
    padding: 10px 25px;
    font-size: 1.1rem;
    font-weight: bold;
    transition: .3s ease all;
}

.btn-quote{
    width: auto;
    border-radius: 25px;
    border: none;
    padding: 10px 25px;
    font-size: 1.1rem;
    font-weight: bold;
    transition: .3s ease all;
    background: var(--color-azul-innovacion);
    color: #ffffff;
    text-decoration: none;
}
.btn-quote:hover{
    background: var(--color-mexican-pink);
    color: #fff; 
}
.btn-contact{
    width: auto;
    border-radius: 25px;
    border: none;
    padding: 10px 25px;
    font-size: 1.1rem;
    font-weight: bold;
    transition: .3s ease all;
    background: #fff;
    color: var(--color-azul-innovacion);
    text-decoration: none;
}

.btn-contact:hover{
    background: var(--color-mexican-pink);
    color: #fff; 
}

.textImgHeader button:nth-child(1) {
    background: var(--color-azul-innovacion);
    color: #ffffff;
}

.textImgHeader button:nth-child(2) {
    background: #fff;
    color: var(--color-azul-innovacion);
}

.textImgHeader button:hover {
    background: var(--color-mexican-pink);
    color: #fff;  
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
    .textImgHeader .description, .textImgHeader .features {
        width: 85%;
        font-size: 1rem;
    }

    .textImgHeader h2 {
        margin-bottom: 20px;
    }

    .textImgHeader .features p {
        font-size: 1.2rem;
    }
    .textImgHeader .btnsContainer {
        margin-top: 20px;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .imgHeaderContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(41, 41, 41, 0.656);
    }
    
    .textImgHeader h5{
        font-size: .9rem;
        font-weight: 600;
        margin-bottom: 5px;
        padding-top: 1rem;
    }

    .textImgHeader h2 {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .textImgHeader .description, .textImgHeader .features {
        width: 100%;
        font-size: .9rem;
        text-align: justify;
    }

    .textImgHeader .features {
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .textImgHeader .features p {
        font-weight: 600;
        font-size: .9rem;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .textImgHeader .btnsContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .textImgHeader button {
        width: auto;
        border-radius: 25px;
        border: none;
        padding: 5px 15px;
        font-size: 1rem;
        font-weight: bold;
        transition: .3s ease all;
    }
}
