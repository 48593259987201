.international_box{
    position: relative;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.texto_box{
    width: 100%;
    position: relative;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
}

.carga_internacional{
    color: #0b2343;
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: 600;
    position: relative;
}

.box_internacional{
    width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 45px;
}

.aerea_internacional{
    color: #999999;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0px;
    position: relative;
}

.conta-texto p{
    color: #666666;
    font-size: 14px;
    line-height: 1.6;
    position: relative;
    margin-bottom: 25px;
    font-weight: 400;
}

.conta-texto h2{
    font-size: 24px;
    color: #0b2343;
    font-weight: 600;
}

.conta-texto h3{
    font-size: 22px;
    color: #0b2343;
    font-weight: 600;
}

.conta-texto h4{
    font-size: 20px;
    color: #0b2343;
    font-weight: 600;
}


/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .texto_box{
        padding: 40px 3px;
    }
  }