.bg-innovation {
    background-color: var(--color-azul-innovacion);
    height: 240px;
}
.restricitions-img {
    width: 200px;
    height: auto;
    padding: 0.5rem;
}
.bg-light-gray {
    background-color: #F2F2EF;
}
.brachycephalic-img {
    width: 120px;
    height: auto;
    padding: 0.5rem;
}
.li-space li {
    padding-bottom: .5rem;
}


@media screen and (max-width: 767px) {
    .restricitions-img {
        width: 160px;
    }
}