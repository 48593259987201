.nav-item .submenu {
    position: absolute;
    background-color: white;
    z-index: 1000;
    text-decoration: none;
    width: 180px;
    height: auto;
    top: 44px;
    text-align: start;
}
.submenu-item {
    margin: 0;
    padding: 0;
    position: relative;
}

.nav-redirect {
    list-style: none;
    text-decoration: none;
}

.nav-redirect:nth-child(1) {
    color: #fff;
}

.submenu-item li {
    font-size: 11px;
    color: var(--color-blue-hard);
    list-style: none;
    margin: 0;
    padding: 8px 10px;
    cursor: pointer;
    transition: .3s ease all;
    position: relative;
    border-bottom: 1px solid #eee;
}
.submenu-item li:hover {
    color: #007CC2;
}
.nav-text{
    margin: 0;
    padding: 5px 10px;
    text-align: center;
    color: white;
    font-size: 13px;
    white-space: nowrap;
    border-radius: 20px;
    transition: .3s ease all;
    cursor: pointer;
}
.submenuRight {
    position: absolute;
    margin: 0;
    padding: 0;
    background: #ffffff;
    left: 180px;
}
@media screen and (max-width: 1024px) {
    .nav-item .submenu {
        position: static;
        width: 100%;
    }
    .submenu-item {
        position: static;
    }
    .submenu-item li {
        font-size: 14px;
        color: #007cc2;
    }
    .nav-text{
        margin: 0;
        padding: 6px 10px;
        text-align: start;
        color: #0B2343;
        font-size: 18px;
        font-weight: bold;
        white-space: nowrap;
        border-radius: 20px;
        transition: .3s ease all;
        cursor: pointer;
    }
    .nav-redirect:nth-child(1) {
        color: #0B2343;
    }
}