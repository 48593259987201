.amc-login {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}

.amc-login form {
  width: 80%;
  height: 100%;
  padding: 10px;
}

/* Formulario */

.amc-login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 89%;
  padding: 20px;
  margin-top: 21px;
}

.amc-login-form .amc-login-form-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #171717;
  font-size: 35px;
  font-weight: bold;
  height: 20%;
}

.amc-login-form .amc-login-form-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 600px;
  height: 80%;
}

/* Perfil */

.amc-login-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: auto;
  width: 100%;
  padding: 10px;
}

@media (max-height: 800px) {
  .amc-login-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: auto;
    width: 100%;
    padding: 10px;
    margin-top: 150px;
  }
}

.amc-login-profile .amc-login-profile-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #171717;
  font-size: 35px;
  font-weight: bold;
  height: 30%;
  width: 100%;
}

.amc-login-profile .amc-login-profile-type-profile {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 30px;
  height: 100%;
  width: 100%;
}

.amc-login-profile .amc-login-profile-type-profile .profile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 190px;
  height: auto;
  gap: 10px;
}

.profile-container .amc-login-profile-circle {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #123660;
  width: 100%;
  height: 90%;
}

.profile-container .amc-login-profile-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profile-container .amc-login-profile-label span {
  color: #171717;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
}

/* Boton */

.amc-login-buton {
  height: 20%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.amc-login-buton .amc-login-buton-next {
  padding: 10px;
  width: 52%;
  font-size: 15px;
  font-weight: bold;
  background-color: #035cf7;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* estilod de los input */
.amc-login-form-inputs .formGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0 10px 0;
  width: 100%;
}

.amc-login-form-inputs .formGroup input,
.amc-login-form-inputs .formGroup textarea,
.date-box {
  background: #ffffff;
  font-size: 0.9rem;
  border: none;
  outline: none;
  border-radius: 0px;
  border: 2px solid #d4d4d4;
  transition: 0.3s ease all;
}

.amc-login-form-inputs .formGroup input {
  height: 45px;
  min-height: 45px;
  padding: 10px 10px 1px 10px;
}

.amc-login-form-inputs .formGroup input:disabled + label {
  opacity: 0.3;
  cursor: auto;
}

.amc-login-form-inputs .formGroup input:focus {
  border: 2px solid #d4d4d4;
}

.amc-login-form-inputs .formGroup input:focus + label,
.amc-login-form-inputs .formGroup input:not(:placeholder-shown) + label {
  transform: translateY(1px) scale(0.6);
  transform-origin: left top;
  color: #323a8f;
}

.amc-login-form-inputs .formGroup input:focus {
  padding: 10px 10px 1px 10px;
}

.amc-login-form-inputs .formGroup label {
  cursor: pointer;
  position: absolute;
  top: 2px;
  transform: translateY(10px);
  transition: transform 0.5s #000 0.3s;
  transition: 0.3s ease all;
  font-size: 1rem;
  margin-bottom: 4px;
  color: #000;
  font-weight: lighter;
}

.amc-login-form-inputs .formGroup select {
  background: #f2f3f5;
  color: #000;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 10px;
  padding-right: 30px;
  border: none;
  outline: none;
  border-radius: 0px;
  border: 2px solid #d4d4d4;
  cursor: pointer;
  appearance: none;
}

.amc-login-form-inputs .formGroup select:disabled {
  cursor: auto;
  opacity: 0.3;
}

.selectGroup:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000000;
  border-radius: 10px;
  pointer-events: none;
}

.selectGroup.disabled:after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  pointer-events: none;
}
/* estilod de los input */

.amc-login-form .alert-text {
  color: red;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
}

@media screen and (min-width: 1920px) {
  /* Formulario */
  .amc-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 89%;
    padding: 20px;
    margin-top: 21px;
  }

  .amc-login-form .amc-login-form-title {
    font-size: 40px;
    font-weight: bold;
    height: 20%;
  }

  .amc-login-form .amc-login-form-inputs {
    width: 85%;
    height: 80%;
  }

  /* Perfil */

  .amc-login-profile .amc-login-profile-title {
    font-size: 40px;
  }

  .amc-login-profile .amc-login-profile-type-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 80%;
    width: 100%;
  }

  .amc-login-profile .amc-login-profile-type-profile .profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: auto;
  }

  .profile-container .amc-login-profile-circle {
    width: 90%;
    height: 60%;
  }

  /* Inputs */
  .amc-login-form-inputs .formGroup input {
    height: 75px;
    min-height: 45px;
    padding: 10px 10px 1px 10px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  /* Perfil */
  .profile-container .amc-login-profile-circle {
    width: 100%;
    height: 60%;
  }
}

@media screen and (min-width: 913px) and (max-width: 1279px) {
}

/* ============================================================
    TABLETS
    ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .amc-login form {
    width: 100%;
    height: 100%;
  }
  /* Formulario */
  .amc-login-form {
    height: 89%;
    padding: 20px;
    margin-top: 0px;
  }

  .amc-login-form .amc-login-form-title {
    height: 40%;
    width: 50%;
  }

  .amc-login-form .amc-login-form-inputs {
    width: 80%;
    height: 60%;
  }

  /* Perfil */

  .amc-login-profile .amc-login-profile-title {
    font-size: 37px;
  }

  .amc-login-profile .amc-login-profile-type-profile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 0px;
    height: 60%;
    width: 100%;
  }

  .amc-login-profile .amc-login-profile-type-profile .profile-container {
    width: 70%;
    height: 100%;
  }
}

/* ============================================================
        PHONE
        ============================================================ */
@media screen and (max-width: 620px) {
  .amc-login form {
    width: 100%;
    height: 100%;
  }

  /* Formulario */
  .amc-login-form {
    height: 89%;
    padding: 20px;
    margin-top: 21px;
  }

  .amc-login-form .amc-login-form-title {
    font-size: 30px;
    height: 40%;
    width: 80%;
  }

  .amc-login-form .amc-login-form-inputs {
    width: 100%;
    height: 60%;
  }

  /* Perfil */

  .amc-login-profile .amc-login-profile-title {
    font-size: 30px;
  }

  .amc-login-profile .amc-login-profile-type-profile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%;
    width: 100%;
  }

  .amc-login-profile .amc-login-profile-type-profile .profile-container {
    width: 100%;
    height: 100%;
  }

  .profile-container .amc-login-profile-circle {
    width: 100%;
    height: 100%;
  }

  .profile-container .amc-login-profile-circle svg {
    width: 100px;
  }

  .profile-container .amc-login-profile-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
  }

  .profile-container .amc-login-profile-label span {
    color: #171717;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    line-height: normal;
  }

  /* Boton */

  .amc-login-buton {
    height: 20%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .amc-login-buton .amc-login-buton-next {
    padding: 10px;
    width: 52%;
    font-size: 11px;
    font-weight: bold;
    background-color: #035cf7;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}
