.acordeonContainer {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.acrodeonItem {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.acrodeonItem h5 {
  margin-bottom: 20px;
  font-weight: bold;
}

.acordeonInfo ul li {
  margin: 10px 0;
}

.acordeonInfo.open {
  max-height: 10000px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.acordeonInfo.closed {
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.ql-container.ql-snow {
  border: none!important;
}

.ql-container {
  font-size: 1rem!important;
}
