.login-reports-form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.login-reports-form button {
  margin-top: 30px;
}

.text-not-found-data {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #737373;
  margin-top: 20px;
}