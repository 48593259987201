.slideBorder {
    width: 60%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIcon .lineClose {
    width: 100%;
    height: 1px;
    background: #fff;
}

.closeIcon .lineClose:nth-child(1) {
    transform: rotate(45deg);
}

.closeIcon .lineClose:nth-child(2) {
    transform: rotate(-45deg);
}

.carouselContainer {
    position: relative;
    width: 100%;
}

.carouselContainer img {
    max-width: 100%;
    height: auto; 
}

.carousel-indicators {
    position: absolute;
    bottom: 0 px!important;
}

.carousel-indicators button {
    border-radius: 50%;
    width: 10px;
    max-width: 10px;
    height: 10px;
    min-height: 10px;
    background: #fff;
  }

.carousel-indicators button.active {
    background-color: var(--color-mexican-pink); 
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
    .slideBorder {
        width: 80%;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .slideBorder {
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .carousel-indicators {
        position: absolute;
        bottom: 0px!important;
    }

    .carouselContainer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
    }
    
    .carouselContainer img {
        max-width: 100%;
        height: auto;
    }
}
