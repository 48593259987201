.bg-landing {
  background: #ececec;
  background-image: url(/public/assets/home/backgroundHeader.jpg);
  background-size: cover;
  background-origin: border-box;
  background-repeat: no-repeat; 
  background-position: 70% 0%;
  height: 55vh;
  position: relative; 
  z-index: 1;
}

.carousel-item-container {
  height: 55vh;
}

.carousel-caption {
  z-index: 2;
  position: relative;
  pointer-events: none; /* Evita que interfiera con los indicadores */
}

.carousel-indicators button {
  border-radius: 50%;
  width: 10px;
  max-width: 10px;
  height: 10px;
  min-height: 10px;
  background: #fff;
  z-index: 3;
}

.carousel-indicators button.active {
  background-color: var(--color-azul-innovacion); 
}

.bg-premium-blue{
  background-color: #020C41;
}

.btn-pink {
  border-radius: 6px;
  background-color: var(--color-mexican-pink);
  border: none;
  padding: 10px 15px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: .3s ease all;
  pointer-events: auto; /* Reactiva los eventos para el botón */
}

.btn-pink:hover {
  background: var(--color-azul-innovacion);
  color: #fff;  
}

.img-services{
  border-radius: 5px;
  width: 280px;
  height: auto;
}

.img-slider{
  margin: 0px 80px;
}