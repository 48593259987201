.shipment__form__row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  position: relative;
  margin-left: -50px;
  margin-bottom: 30px;
}

.shipment__form__column {
  width: calc(50% - 50px);
  position: relative;
  margin-left: 50px;
}

.shipment__form label {
  font-size: 12px;
  margin-bottom: 14px;
}

.shipment__form label i {
  color: #e91b2f;
  font-style: normal;
  display: inline-block;
  position: relative;
  margin-right: 4px;
}

.shipment__form__radio {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: -12px;
  position: relative;
}

.shipment__form__radio div {
  width: calc(50% - 12px);
  margin-left: 12px;
  position: relative;
}

.js-shipment-quote-form-pago {
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 15;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.paidSelect {
  color: #0b2343;
  border: 1px solid #e5e5e5;
  background-color: white;
  transition: all 0.3s ease-in-out;
  font-family: NeueHaasUnicaPro-Regular, sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  width: 100%;
  margin: 0px;
  border-radius: 34px;
  display: inline-block;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.37rem 0.75rem;
  line-height: 3;
}

.paidSelect:hover {
  background-color: var(--color-mexican-pink);
  color: #fff;
}

.buttonActive {
  color: white !important;
  border: 1px solid var(--color-azul-innovacion) !important;
  background-color: var(--color-azul-innovacion) !important;
}

.shipment__form__number {
  width: 100%;
  position: relative;
}

.js-shipment-quote-form-piezas {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  outline: 0px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  padding: 14px 16px;
  position: relative;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  color: #212121;
}

.shipment__form__number__controls {
  top: 50%;
  right: 20px;
  z-index: 15;
  position: absolute;
  transform: translateY(-50%);
  width: 10px;
}

.shipment__form__number__controls button {
  width: 12px;
  height: 12px;
  margin: 0px;
  display: block;
  padding: 0px;
  position: relative;
  border: 0px;
  border-radius: 0px;
  text-align: center;
  z-index: 20;
  background-color: white;
}

.shipment__form__number__controls button svg {
  width: 9px;
  fill: #212121;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s ease-in-out;
}

.shipment__form__weight {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: -12px;
  position: relative;
}

.shipment__form__weight .column {
  width: calc(50% - 12px);
  margin-left: 12px;
  position: relative;
}

.js-shipment-quote-form-peso {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  outline: 0px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  background-color: white;
  position: relative;
  padding: 14px 16px;
  transition: all 0.3s ease-in-out;
  color: #212121;
  margin: 0;
}

.js-shipment-quote-form-unidades {
  width: 100%;
  font-size: 14px;
  display: block;
  position: relative;
  border: 1px solid #e5e5e5;
  z-index: 15;
}

.shipment__form__money {
  width: 100%;
  position: relative;
}

.js-shipment-quote-form-valor {
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  outline: 0px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  background-color: white;
  position: relative;
  padding: 14px 16px;
  transition: all 0.3s ease-in-out;
  color: #212121;
}

.shipment__form__money__icon {
  top: 50%;
  right: 17px;
  z-index: 2;
  position: absolute;
  transform: translateY(-50%);
  background-color: #ffffff;
  width: 15px;
}

.shipment__form__description {
  width: 100%;
  position: relative;
  margin-bottom: 80px;
}

.js-shipment-quote-form-description {
  font-family: NeueHaasUnicaPro-Regular, sans-serif;
  font-size: 12px;
  width: 100%;
  resize: none;
  outline: 0px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.botonInactivo {
  color: rgba(0, 0, 0, 0.2) !important;
  opacity: 0.6 !important;
  cursor: not-allowed !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .shipment__form__description {
    width: 36.5rem;
    position: relative;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 600px) {
  .shipment__form__description {
    width: 20rem;
    position: relative;
    margin-bottom: 80px;
  }
}
