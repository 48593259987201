.containerButtonContact {
    position: relative;
}

.btnActionModal {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btnActionModalMb {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.backgroundModal {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0%;
    left: 0%;
    background: #ffffff00;
    z-index: 80;
}

.backgroundModalMb {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0%;
    left: 0%;
    background: #00000074;
    z-index: 80;
}

.btnActionModal button {
    border-radius: 50%;
    border: none;
    width: 50px;
    height: 50px;
    background: var(--color-mexican-pink);
}

.btnActionModalMb button {
    border-radius: 50%;
    border: none;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-mexican-pink);
}

.btnActionModal button img {
    transform: scale(.7);
}

.btnActionModalMb button img {
    transform: scale(.65);
}

.btnActionModal p {
    margin-top: 3px;
    font-size: .8rem;
    margin: 0;
    padding: 0;
    color: black;
}

.btnActionModalMb p {
    margin-top: 3px;
    font-size: .7rem;
    margin: 0;
    padding: 0;
    color: black;
}

.trackInfoBox.active {
    position: absolute;
    width: 450px;
    height: 350px;
    background: var(--color-premium-blue);
    border-radius: 5px;
    z-index: 90;
    transition: all .4s ease;
    /* transform: translate(70px, -370px); */
    top: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.trackInfoBox.disabled {
    position: absolute;
    top: 40px;
    left: 60px;
    width: 10px;
    height: 10px;
    background: var(--color-premium-blue);
    border-radius: 5px;
    opacity: 0;
    transition: all .4s ease;
    overflow: hidden;
    /* transform: translate(0%, 0%); */
}

.trackInfoBoxMb.active {
    position: absolute;
    top: 0;
    left: calc(50% - 200px);
    width: 450px;
    height: 350px;
    background: var(--color-premium-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
    transform: translate(0%, 50%);
    transition: all .4s ease;
    overflow: hidden;
}

.trackInfoBoxMb.disabled {
    position: absolute;
    top: 50px;
    right: 20px;
    width: 10px;
    height: 10px;
    background: var(--color-premium-blue);
    border-radius: 5px;
    opacity: 1;
    z-index: 90;
    transition: all .4s ease;
    overflow: hidden;
    transform: translate(100%, 50%);
}

.contentModal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #ffffff;
}

.contentModal p.title {
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
    border-bottom: 1px solid var(--color-primary);
    padding: 0 0px 10px 0px;
}

.contentModal p.customService {
    text-align: center;
    font-size: .8rem;
    color: #dbdbdb;
    margin: 0;
    padding: 0;
}

.contentModal a.phoneText {
    font-weight: bold;
    font-size: 1.3rem;
    color: #fff;
}

.contentModal .infoBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.contentModal .infoBox p.title {
    width: 100%;
    font-size: .7rem;
    text-align: start;
    margin: 0;
    margin-bottom: 5px;
}

.contentModal .infoText {
    display: flex;
    padding: 0 15px;
}

.contentModal .infoBox p {
    font-size: .7rem;
    width: 100px;
    margin: 2px 0;
    padding: 0;
}

.contentModal .infoText span {
    font-size: .7rem;
    font-weight: bold;
    margin: 2px 0;
    padding: 0;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 990px) {
    
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {

}