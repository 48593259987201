.trackStatusContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2rem;
}
.scrollPartial {
    width: 100%;
    height: auto;
    max-height: 60vh;
    overflow-x: auto;
    overflow-y: auto;
    scrollbar-color: var(--color-mexican-pink) var(--color-premium-blue);
    scrollbar-width: thin;
}

.scrollPartial::-webkit-scrollbar {
    width: 12px;
}

.scrollPartial::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* +++++++++++++++++++++++++++++++++++++++
New Design
+++++++++++++++++++++++++++++++++++++++ */
.trackHeaderTitle {
    width: 100%;
    background: var(--color-premium-blue);
    background-image: url('../../../assets/imgs/track/lines.svg');
    background-repeat: repeat-y;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 15px 25px;
}

.trackHeaderTitle h5 {
    color: #fff;
    margin: 0;
    padding: 0;
}

.partialContainerMbl{
    width: 100%;
}
.headerPartialMbl {
    width: 100%;
    background: var(--color-premium-blue);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: .3s;
}
.headerPartialMbl:hover {
    width: 100%;
    background: var(--color-premium-blue);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    opacity: .9;
}

.headerPartialMbl p {
    margin: 0;
    padding: 0;
}

.headerPartialMbl button {
    border: none;
    background: none;
    color: #fff;
}
.bodyPartialMbl {
    width: 100%;
    display: flex;
}
.bodyPartialMbl.open {
    width: 100%;
    max-height: 500px;
    display: flex;
    transition: all 0.5s ease-in;
    background: #eee;
}
.bodyPartialMbl.closed {
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    display: flex;
    transition: all 0.5s ease;
}
.imgSectionMbl {
    width: 30%;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.verticalLine {
    width: 5px;
    height: 30px;
    background: var(--color-mexican-pink);
}
.verticalLineOff {
    width: 5px;
    height: 30px;
    background: #a8a8a8;
}
.verticalLineNone {
    width: 5px;
    height: 30px;
    background: none
}
.imgSectionMbl .icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    z-index: 2;
    overflow: hidden;
}
.imgSectionMbl .icon img {
    width: 50px;
}
.infoPartialMbl {
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.infoPartialMbl .status {
    width: 60%;
    padding-left: 10px;
}
.infoPartialMbl .status p {
    font-weight: bold;
}
.infoPartialMbl .details {
    width: 40%;
    text-align: center;
}
.infoPartialMbl .details p {
    margin: 0;
    font-size: .8rem;
    font-weight: bold;
    cursor: pointer;
}
.TrackBtnsStatusMbl {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 30px;
}
.TrackBtnsStatusMbl button {
    border: 1px solid var(--color-premium-blue);
    background: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: .8rem;
    font-weight: bold;
    transition: .3s ease all;
    color: var(--color-premium-blue);
}
.TrackBtnsStatusMbl button.btnPrint {
    background: var(--color-premium-blue);
    color: #fff;
}

.contAnimation {
    width: 30%;
    height: 0px;
    background: coral;
    position: relative;
    display: flex;
    justify-content: center;
}

.rcfAnimationMb {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgb(3, 92, 247);
    border: 1px solid rgb(3, 92, 247);
    position: absolute;
    z-index: 1;
    animation-name: rcfAnimationMb;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.rcfAnimationNoneMb {
    display: none;
}

@keyframes rcfAnimationMb {
    0% {scale: .5; opacity: 1;}
    50% {scale: 1; opacity: .7;}
    100% {scale: 1.5; opacity: 0;}
}


/* +++++++++++++++++++++++++++++++++++++++
END New Design
+++++++++++++++++++++++++++++++++++++++ */

.trackBtnLeft {
    width: 13%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;
}

.targetsContainer {
    width: 74%;
    display: flex;
    scroll-behavior: smooth;
    overflow-x: scroll;
    padding-bottom: 15px;
}

.targetsContainer::-webkit-scrollbar {
    display: none;
}

.trackBtnRight {
    width: 13%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
}

.targetStatus {
    min-width: 200px;
}

.targetStatus .iconConten {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.iconLineContent {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 15px;
}

.iconLineContent .lineSolid {
    width: 50%;
    border-top: 6px solid var(--color-premium-blue);
    z-index: 1;
}

.iconLineContent .lineSolidOff {
    width: 50%;
    border-top: 6px solid #a8a8a8;
    z-index: 1;
}

.iconLineContent .lineDashed {
    width: 50%;
    border-top: 6px dashed #eee;
    z-index: 1;
}

.iconLineContent .lineNone {
    width: 50%;
    border-top: 6px solid none;
    z-index: 1;
}

.backgroundSolid {
    background: var(--color-premium-blue);
}

.backgroundSolidOff {
    background: #a8a8a8;
}

.colorSolid {
    color: var(--color-premium-blue);
}

.colorSolidOff {
    color: #a8a8a8;
}

.iconLineContent .icon {
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    z-index: 2;
}

.rcfAnimation {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgb(3, 92, 247);
    border: 1px solid rgb(3, 92, 247);
    position: absolute;
    z-index: 1;
    animation-name: rcfAnimation;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
}

.rcfAnimationNone {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
}

@keyframes rcfAnimation {
    0% {scale: 1; opacity: 1;}
    50% {scale: 1.7; opacity: .7;}
    100% {scale: 2.4; opacity: 0;}
}

.iconLineContent .icon img {
    width: 38px;
}

.iconConten .titleTarget {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    gap: 3px;
}

.titleTarget h5 {
    width: 90%;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.titleTarget h5:nth-child(2) {
    font-size: 1rem;
    margin-top: 5px;
}

.targetStatus .partialSection {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partialPairBg {
    background: #eee;
}

.partialOneBg {
    background: #ffffff;
}

.partialSection p {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: .7rem;
    font-weight: bold;
    color: var(--color-premium-blue);
}

.btnTrackContainer {
    width: auto;
    height: 200px;
    display: flex;
    align-items: center;
}

.btnTrackContainer button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #999999;
    background: none;
    color: #858585;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    transition: .4s ease all;
    margin: 0 10px;
}

.btnTrackContainer button:hover {
    border: 2px solid var(--color-mexican-pink);
    color: var(--color-mexican-pink);
}

.partialSectionKey {
    width: auto;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.partialSectionKey p {
    margin: 2px 0;
    padding: 0;
    font-size: .7rem;
    font-weight: bold;
    text-align: center;
}

.localTime {
    font-size: 11px;
    font-weight: bold;
    color: #8d807a;
    margin-top: 20px;
    margin-left: 0;
    margin-bottom: 5px
}

.PendingStatuses {
    font-size: 11px;
    font-weight: bold;
    color: #828282;
    margin: 4px;
    padding: 0;
    margin-left: 0;
}

.ScheduleInfor {
    font-size: .5rem;
    font-weight: bold;
    color: #828282;
    margin-left: 4%;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 990px) {
    .scrollPartial {
        width: 100%;
        height: auto;
        max-height: none;
        overflow-x: auto;
        overflow-y: auto;
        scrollbar-color: var(--color-mexican-pink) var(--color-premium-blue);
        scrollbar-width: thin;
    }
    .TrackBtnsStatusMbl button {
        border: 1px solid var(--color-mexican-pink);
        background: var(--color-mexican-pink);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: .8rem;
        font-weight: bold;
        transition: .3s ease all;
        color: #fff;
    }
    .TrackBtnsStatusMbl button.btnPrint {
        background: var(--color-mexican-pink);
        color: #fff;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .partialContainerMbl{
        width: 100%;
        margin-bottom: 10px;
    }
    .headerPartialMbl {
        width: 100%;
        background: var(--color-premium-blue);
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        cursor: pointer;
    }

    .headerPartialMbl p {
        margin: 0;
        padding: 0;
    }

    .headerPartialMbl button {
        border: none;
        background: none;
        color: #fff;
    }
    .bodyPartialMbl {
        width: 100%;
        display: flex;
    }
    .bodyPartialMbl.open {
        width: 100%;
        max-height: 500px;
        display: flex;
        transition: max-height 0.5s ease-in-out;
    }
    .bodyPartialMbl.closed {
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        display: flex;
        transition: max-height 0.5s ease-in-out;
    }
    .imgSectionMbl {
        width: 30%;
        height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .verticalLine {
        width: 5px;
        height: 30px;
        background: var(--color-mexican-pink);
    }
    .verticalLineOff {
        width: 5px;
        height: 30px;
        background: #a8a8a8;
    }
    .verticalLineNone {
        width: 5px;
        height: 30px;
        background: none
    }
    .imgSectionMbl .icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        display: flex;
        color: #fff;
        justify-content: center;
        align-items: center;
        font-size: 2.2rem;
        z-index: 2;
    }
    .imgSectionMbl .icon img {
        width: 38px;
    }
    .infoPartialMbl {
        width: 70%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid #ccc;
    }
    .infoPartialMbl .status {
        width: 60%;
        padding-left: 10px;
    }
    .infoPartialMbl .status p {
        font-weight: bold;
    }
    .infoPartialMbl .details {
        width: 40%;
        text-align: center;
    }
    .infoPartialMbl .details p {
        margin: 0;
        font-size: .8rem;
        font-weight: bold;
        cursor: pointer;
    }
    .TrackBtnsStatusMbl {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 30px;
    }
    .TrackBtnsStatusMbl button {
        border: 1px solid var(--color-mexican-pink);
        background: var(--color-mexican-pink);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: .8rem;
        font-weight: bold;
        transition: .3s ease all;
        color: #fff;
    }
    .TrackBtnsStatusMbl button.btnPrint {
        background: var(--color-mexican-pink);
        color: #fff;
    }

    .contAnimation {
        width: 30%;
        height: 0px;
        background: coral;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .rcfAnimationMb {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: rgb(3, 92, 247);
        border: 1px solid rgb(3, 92, 247);
        position: absolute;
        z-index: 1;
        animation-name: rcfAnimationMb;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
    
    .rcfAnimationNoneMb {
        display: none;
    }
    
    @keyframes rcfAnimationMb {
        0% {scale: .5; opacity: 1;}
        50% {scale: 1; opacity: .7;}
        100% {scale: 1.5; opacity: 0;}
    }
}
  