.loginImgBg {
    width: 100%;
    height: 100vh;
    background: coral;
    background-image: url(/public/assets/home/BackgroundLogin.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #fff;
}

.loginContainer {
    width: 100%;
    max-width: 1500px;
    display: flex;
    align-items: center;
    position: relative;
}

.loginBackImage {
    width: 80%;
    overflow: hidden;
    padding: 15px;
    padding-bottom: 3rem;
    border-radius: 10px;
    background: #1b2f52;
    position: relative;
}

.loginBackImage img.imgLoginBackLeft {
    width: 100%;
    height: 78vh;
}

.loginBackImage img.groupAmc {
    position: absolute;
    bottom: 2%;
    left: 5%;
    width: 20%;
}

.loginFormContainer {
    width: 30%;
    height: 95%;
    background: var(--color-premium-blue);
    position: absolute;
    right: 0px;
    border-radius: 10px;
    border: 7px solid #1b2f52;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginFormContainer img {
    margin-bottom: 0px;
    width: 80%;
}

.loginFormContainer form {
    width: 80%;
}

.loginFormGroup {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 30px 0;
}

.loginValidClass {
    margin-top: 50px;
    padding: 5px 40px;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    background: var(--color-red);
    cursor: pointer;
}

.loginBtnSubmit p {
    font-size: .8rem;
    margin-top: 10px;
    cursor: pointer;
    transition: .3s ease all;
}

.loginBtnSubmit p:hover {
    text-decoration: underline;
}

.contCopyright {
    width: 100%;
    max-width: 1500px;
    margin-top: 10px;
}

.contCopyright span {
    font-size: .8rem;
    margin-left: 4%;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
    .loginFormContainer {
        width: 50%;
    }
    
    .loginBackImage img.imgLoginBackLeft {
        width: 100%;
        height: auto;
    }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
    .loginContainer {
        display: flex;
        flex-direction: column;
    }
    .loginBackImage {
        width: 100%;
        height: auto;
    }
    .loginBackImage img.imgLoginBackLeft {
        width: 100%;
        height: auto;
    }
    .loginBackImage img.groupAmc {
        display: none;
    }
    .loginFormContainer {
        width: 100%;
        position: relative;
    }
    .contCopyright span {
        font-size: .6rem;
        margin-left: 0;
    }
}