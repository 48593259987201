.header-weighing {
  width: 100%;
  height: 200px;
  background: var(--color-premium-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(../../../../../assets/imgs/track/lines.svg);
  background-repeat: repeat-y;
  background-position: right;
  margin-bottom: 30px;
}

.header-weighing .logo-amc {
  width: 300px;
}

.body-weighing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.body-weighing h4 {
  font-size: 1.8rem;
  margin-bottom: 40px;
}

.body-weighing .caption {
  font-weight: bolder;
  color: #737373;
  font-size: 1rem;
}

.body-weighing .table-container {
  width: 100%;
  height: 27rem;
  max-height: 27rem;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.body-weighing table {
  width: 100%;
  max-width: 1500px;

  border-collapse: separate;
  border-spacing: 10px 5px;
  margin-top: 1rem;
}

.body-weighing table thead {
  background-color: #a3a3a3;
  color: white;
}

.body-weighing table thead th {
  padding: 0.75rem;
  text-align: center;
  font-weight: bold;
}

.body-weighing table tbody td {
  padding: 0.75rem;
  border: 1px solid black;
  text-align: center;
}

.body-weighing table tbody td:first-child {
  background: var(--color-premium-blue);
  color: #fff;
}

.body-weighing table .status {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  color: white;
}

.body-weighing table .status.approved {
  background-color: #66c87b;
}

.body-weighing table .status.in-process {
  background-color: #eec200;
  color: #fff;
}

.body-weighing table .status.validated {
  background-color: #035cf7;
  color: #fff;
}
.body-weighing table .status.documenting {
  background-color: #123660;
  color: #fff;
}
