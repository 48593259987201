.bg-fleet {
    background-image: url(../../../assets/imgs/about-us/fleet/cover.png);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: right;
}
.btn-box {
    width: 90%;
    padding:25px 5px;
    background-color: #fff;
    border: 1px solid var(--color-blue-light);
    border-radius: 4px;
    cursor: pointer;
    color: black;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
}
.btn-box:hover {
    background: var(--color-mexican-pink);
    color: #fff; 
}
.btn-box:hover p {
    color: #fff; 
}
.fs-see {
    font-size: 10px;
    text-decoration: underline;
    color: var(--color-blue-light);
    margin: 0;
    padding: 1rem 0;
}
/* Aircrafts */
.text-rotate {
    font-size: 13vw;
    color: rgba(0,61,100,0.2);
    transform: rotate(-90deg);
    white-space: nowrap;
}
.fleet-img-container {
    background-color: #F2F2F2;
    height: 50vh;
    display: flex;
    justify-content: center;
    padding: 0 30px;
}
.fleet-img-container img {
    width: 100%;
    height: auto;
}
.btn-fleet {
    width: auto;
    border-radius: 25px;
    background-color: var(--color-azul-innovacion);
    border: none;
    padding: 10px 25px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    transition: .3s ease all;
}    
.btn-fleet:hover {
    background: var(--color-mexican-pink);
    color: #fff;  
}

/* ============================================================
TABLETS
============================================================ */

@media screen and (min-width: 576px) and (max-width: 768px) {
    .btn-fleet {
      width: 65%;
    }
  }

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 575px) {
    .btn-fleet {
      width: 90%;
    }
  }