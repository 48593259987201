.shipment__table {
  width: 100%;
  position: relative;
}

.shipment__table__layout {
  width: 100%;
  position: relative;
  padding-left: 8.33333%;
  padding-right: 8.33333%;
}

.shipment__table__title {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.shipment__table__title h2 {
  color: #0b2343;
  font-size: 30px;
  position: relative;
  margin-bottom: 0px;
}

.shipment__table__information {
  width: 100%;
  position: relative;
}

.set {
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 600;
  color: #1b8198;
  font-size: 16px;
  margin-bottom: 20px;
}

.shipment__table__information ul {
  width: 100%;
  list-style: none;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 80px;
  padding: 0px;
  position: relative;
}

.shipment__table__information ul li {
  position: relative;
}

.shipment__table__header {
  width: 100%;
  display: flex;
  position: relative;
}

.shipment__table__header div.is-column-seven {
  width: 14.28571%;
}

.shipment__table__header div {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  z-index: 1;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 600;
  color: #0b2343;
  font-size: 14px;
  text-transform: capitalize;
}

.shipment__table__body {
  width: 100%;
  display: flex;
  position: relative;
}

.shipment__table__body div.is-column-seven {
  width: 14.28571%;
}

.shipment__table__body div {
  -webkit-box-flex: 1;
  flex-grow: 1;
  position: relative;
  color: white;
  z-index: 20;
  padding-top: 10px;
  padding-bottom: 10px;
}

.shipment__table__body div label {
  font-size: 16px;
  color: #007cc2;
  line-height: 1;
  text-transform: capitalize;
  display: none;
  position: relative;
  margin-bottom: 6px;
}

.shipment__table__body div data {
  color: #666;
  font-size: 15px;
  text-transform: capitalize;
  display: block;
  position: relative;
}

.shipment__table__header div.is-column-four {
  width: 25%;
}

.shipment__table__body div.is-column-four {
  width: 25%;
}

.shipment__table__information__footer {
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
}

.shipment__table__information__footer__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shipment__table__information__footer__column__left {
  width: 50%;
  position: relative;
}

.shipment__table__information__footer__column__left label {
  color: #0b2343;
}

.checkbox__label {
  font-family: "Garnett-Semibold", sans-serif;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.checkbox__label.is-square span {
  border-radius: 3px;
  border: 1px solid #d6d6d6;
}

.checkbox__label span {
  top: 1px;
  line-height: 1;
  margin-top: 0px;
  margin-right: 4px;
  margin-bottom: 0px;
  padding: 0px;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #007cc2;
  background-color: transparent;
}

.checkbox__label span svg {
  width: 10px;
  fill: none;
  top: 50%;
  left: 50%;
  z-index: 20;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.checkbox__label.is-square span:after {
  border-radius: 3px;
}

.checkbox__label span:after {
  content: "";
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  background-color: #007cc2;
}

.checkbox__label data {
  display: inline-block;
  position: relative;
}

.shipment__table__information__footer__column__left a {
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 600;
  color: #007cc2;
  display: inline-block;
  position: relative;
  z-index: 20;
  text-decoration: underline;
}

.shipment__table__information__footer__column__right {
  width: 50%;
  position: relative;
  text-align: right;
  margin-bottom: 2rem;
}

.shipment__table__information__footer__column__right a.is-disabled,
.shipment__table__information__footer__column__right button.is-disabled {
  color: white;
  opacity: 1;
  cursor: not-allowed;
  background-color: #ccc;
  width: 10rem;
  height: 3rem;
}

.shipment__table__information__footer__column__right button {
  font-size: 14px;
  padding-left: 55px;
  padding-right: 55px;
}

.shipment__table__information__footer__column__right button {
  font-family: "Garnett-Regular", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 15px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: var(--color-azul-innovacion);
}
.shipment__table__information__footer__column__right button:hover {
  background-color: var(--color-mexican-pink);
}

.btn,
.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (min-width: 601px) and (max-width: 912px) {
  .shipment__table__header {
    display: none;
  }

  .shipment__table__header div {
    font-size: 13px;
  }

  .shipment__table__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .shipment__table__body div.is-column-seven {
    width: 33.33333%;
  }

  .shipment__table__body div label {
    display: inline-block;
  }

  .shipment__table__body div data {
    font-size: 14px;
  }

  .shipment__table__body div.is-column-four {
    width: 41.66667%;
  }

  .shipment__table__information__footer__column__left {
    width: 100%;
}

.shipment__table__information__footer__column__left label {
  font-size: 12px;
}
  
  .shipment__table__information__footer__column__right {
    width: 100%;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    text-align: left;
    margin-bottom: 15px;
}

.shipment__table__information__footer__column__right a, 
.shipment__table__information__footer__column__right button {
  width: 100%;
  font-size: 13px;
  padding-left: 65px;
  padding-right: 65px;
}

.shipment__table__information__footer__column__right a.is-disabled, .shipment__table__information__footer__column__right button.is-disabled {
  color: white;
  opacity: 1;
  cursor: not-allowed;
  background-color: #ccc;
  width: 100%;
  height: 3rem;
}
}

@media screen and (max-width: 600px) {
  .shipment__table__header {
    display: none;
  }

  .shipment__table__header div {
    font-size: 13px;
  }

  .shipment__table__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .shipment__table__body div.is-column-seven {
    width: 33.33333%;
  }

  .shipment__table__body div label {
    display: inline-block;
  }

  .shipment__table__body div data {
    font-size: 14px;
  }
  
  .shipment__table__body div.is-column-four {
    width: 50%;
  }
  
  .shipment__table__information__footer__column__left {
    width: 100%;
}

.shipment__table__information__footer__column__left label {
  font-size: 12px;
}
  
  .shipment__table__information__footer__column__right {
    width: 100%;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    text-align: left;
    margin-bottom: 15px;
}

.shipment__table__information__footer__column__right a, 
.shipment__table__information__footer__column__right button {
  width: 100%;
  font-size: 13px;
  padding-left: 65px;
  padding-right: 65px;
}

.shipment__table__information__footer__column__right a.is-disabled, .shipment__table__information__footer__column__right button.is-disabled {
  color: white;
  opacity: 1;
  cursor: not-allowed;
  background-color: #ccc;
  width: 100%;
  height: 3rem;
}

}
